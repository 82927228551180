

::selection {

    color: #fff;

    background: #173555;

}

::-moz-selection {

    color: #fff;

    background: #173555;

}

::-webkit-selection {

    color: #fff;

    background: #173555;

}

a:hover,

a:focus, a.link {

    color: #2d3e50;

}

.search-full, .search-input {

    background: #173555;

}

.search-close {

    color: #173555;

}

.btn-primary, .btn-primary, .product:hover .add-fav:hover, .product:hover .add-fav.active {

    background-color: #2f68a3;

    color: #FFFFFF;

}



.btn-primary:active, .btn-primary.active, .open .dropdown-toggle.btn-primary {

    background: #173555;

}

.btn-primary:hover, .btn-primary:focus, .btn-primary:active, .btn-primary.active, .open .dropdown-toggle.btn-primary, .pagination > .active > a, .pagination > .active > span, .pagination > .active > a:hover, .pagination > .active > span:hover, .pagination > .active > a:focus, .pagination > .active > span:focus {

    background-color: #173555;

    border-color: #173555;

    color: #FFFFFF;

}

.navbar-top {

    background: #173555;

}

.navbar-tshop {

    background: #173555;

}

.site-color {

    color: #173555 !important;

}



.navbar-nav > li > a {

    color: #fff;

}



.nav > li.active, .nav > li > a:focus {

    background: #0e243a;
}

.nav > li > a:hover, .nav > li:hover > a {

    background: #FFF;
    color:#000;
}


.nav .open > a, .nav .open > a:hover, .nav .open > a:focus, .cartMenu:hover > a.dropdown-toggle {

    background: #173555;

}



.dropdown-menu {

    border-top: solid #173555;

}



.search-box:hover .form-control {

    background: #173555;

}



.search-box:hover .btn-nobg {

    color: #173555;

}



.searchInputBox .search-btn {

    color: #fff;

}



.mCS-dark-2 > .mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {

    background: rgba(149, 165, 166, 0.7) !important;

}



.action-control a:hover, .action-control a.active {

    color: #fff;

    background: #173555;

}



.featuredImgLook2 .inner:hover {

    border: 1px solid #173555;

}



.subCategoryList .thumbnail:hover {

    border: 1px solid #173555;

}



.pager2 a {

    border: 1px solid #173555;

}



.pager2 a.selected {

    background: #173555;

}



.btn-site, .newsLatterBox .btn {

    background: #173555;

}



.btn-discover:hover, .btn-site:hover, .newsLatterBox .btn:hover {

    background: #173555;

}



.full {

    width: 100%;

}



.footer a:hover {

    color: #c5c5c5;

}



.footer {

    /*border-top: solid 3px #173555;*/

}



.modal-header {

    background: #173555;

}



.form-control:focus {

    border-color: #173555;

}



.new-product {

    background: rgba(149, 165, 166, 0.95) !important;

}



#pager a.cycle-pager-active {

    background: rgba(149, 165, 166, 0.5);

}



/* tree menu */



.nav.tree > li.active, .nav.tree > li > a:hover, .nav.tree > li > a:focus, .nav.tree > li:hover > a {

    background: none;

    color: #173555;

}



.nav.tree .open-tree > a, .nav.tree .open-tree > a:hover, .nav.tree .open-tree > a:focus {

    background: none;

    font-weight: bold;

    color: #173555;

}



.panel-default > .panel-heading {

    border-top: 4px solid #173555;

}



.nav-tabs > li.active > a, .nav-tabs > li.active > a:hover, .nav-tabs > li.active > a:focus, .nav-tabs > li:hover > a {

    background: #BFC9CA;

}



.nav-tabs {

    border-bottom: 3px solid #BFC9CA;

}



.socialIcon a:hover {

    background: #173555;

}



.cart-actions button, .cart-actions .wishlist {

    background: none repeat scroll 0 0 #173555;

    border: 2px solid #262625;

}



.cart-actions button:hover {

    background: #fff;

    color: #2d3e50;

    border: solid 2px #2d3e50;

}



.sp-thumbs a:hover, .sp-thumbs a:active, .sp-current, sp-current:visited {

    border: 2px solid #2d3e50 !important;

}



.promo-1 {

    background: none repeat scroll 0 0 #173555;

}



@media (max-width: 767px) {

    .miniCartFooter {

        background: #173555 !important;

    }

}



#pager2 span.cycle-pager-active {

}



#pager2 span.cycle-pager-active {

    background: #173555 !important;

}



#pager2 span {

    border: 2px solid #173555

}



.orderStep li.active a {

    background: #2d3e50;

}



.orderStep li.active a:after {

    border-top-color: #173555 !important;

}



.orderStep li a {

    background: #2c88c5;

}



darkcoilor {

    background: #2c88c5;

}



citecolor {

    color: #2d3e50 !important;

}

