@charset "utf-8";

/* =============================================================== */

/*  TABLE CONTENT



1.   IMPORT

2.   GENERAL FOUNDATION

3.   TYPOGRAPHY + GLOBAL CLASS [ HEADING + GLOBAL CLASS]

4.   CUSTOMIZE BOOTSTRAP  [ MODAL || INPUT FORM ELEMENT || BUTTON ]

5.   CUSTOMIZE NAVBAR  [ Main Navigation || MiniCart Dropdown Menu  || Search Bar  ]

6.   HOME PAGE SLIDER [Cycle Slider || Hope V1, Home V2]

7.   HOME PAGE BOXES SLIDER [Swiper Slider || Fore More see > idangerous.swiper.css ]

8.   HOME PAGE LOOK 2

9.   HOME PAGE LOOK 3

10.  MAIN CONTAINER [NEW ARRIVALS]

11.  PARALLAX SECTION 1, 2

12.  CATEGORY PAGE

13.  PRODUCT DETAILS PAGE

14.  CART PAGE

15.  CHECKOUT PAGE

16.  USER END [Account || Address || Order || Creation]

17.  ABOUT PAGE

18.  CONTACT US PAGE

19.  PRODUCT MODAL [Qick View]

20.  SUBSCRIBE | PROMOTIONAL MODAL 

21.  WEB PAGE PROGRESS BAR CSS

22.  CUSTOM RESPONSIVE



/* =============================================================== */

/* IMPORT CSS 

/* =============================================================== */



@import url("owl.carousel.css");

/* owl.carousel */

@import url("owl.theme.css");

/* owl.carousel */

@import url("font-awesome.css");

/* Font Awesome Icon */

@import url("animate.min.css");

/* css3 animation */

@import url("jquery.mCustomScrollbar.css");

/* mCustomScrollbar */

@import url("alpha.css");

/* Background opacity IE fix */

@import url("https://cdnjs.cloudflare.com/ajax/libs/select2/4.0.0/css/select2.min.css");

/* jQuery Custom Select by select2 */

@import url("smoothproducts.css");

/* Product zoom  */

@import url("../plugins/icheck-1.x/skins/all.css");

/* ion.checkRadio.min.js || customs checkbox and radio plugin  */

@import url("skin-11.css");

/* btn styled from btn.less  */

@import url("btn.css");

/* Template Theme color skin */

@import url(http://fonts.googleapis.com/css?family=Source+Sans+Pro:400,200,200italic,300,300italic,400italic,600,600italic,700,700italic,900,900italic);

@import url(http://fonts.googleapis.com/css?family=Oswald:400,300,700);



/* google webfont */



/* =============================================================== */

/* FOUNDATION

/* =============================================================== */



a {

    color: #454545;

    text-decoration: none;

    outline: none !important;

    -moz-transition: all .2s ease-in;

    -webkit-transition: all .2s ease-in;

    -o-transition: all .2s ease-in;

    transition: all .2s ease-in;

}



a:hover, a:focus {

    text-decoration: none;

    cursor: pointer;

}



ul, ol {

    list-style: none;

    margin: 0;

    padding: 0;

}



body {

    margin: 0;

    padding: 0;

    color: #454545;

    /*font-family: "Times New Roman", Times, serif;*/
    font-family: 'Source Sans Pro', sans-serif; 

    font-size: 14px;

    line-height: 21px;

    position: relative;

    background: #fff;

}



/* =============================================================== */

/* TYPOGRAPHY + GLOBAL CLASS

/* =============================================================== */



h1, h2, h3, h4, h5, h6 {

   /*font-family: "Times New Roman", Times, serif; */
    font-family: 'Source Sans Pro', sans-serif;


    font-weight: normal;

    margin: 0;

    padding-bottom: 15px;

}



h1 {

    font-size: 30px;

    line-height: 35px;

}



h2 {

    font-size: 24px;

    line-height: 28px;

}



h3 {

    font-size: 20px;

    line-height: 24px;

}



h4 {

    font-size: 17px;

    line-height: 20px;

}



h5 {

    font-size: 14px;

    line-height: 20px;

}



h6 {

    font-size: 12px;

    line-height: 20px;

}



p.lead {

    font-size: 20px;

    line-height: 1.4;

}



h3 code {

    font-size: 14px;

    font-weight: normal;

}



.block-title-1 {

    display: block;

    /*font-family: "Times New Roman", Times, serif;*/
 
    font-family: 'Oswald', sans-serif; 

    font-size: 14px;

    font-weight: normal;

    letter-spacing: 2px;

    margin: 15px 20px 0 0;

    text-transform: uppercase;

}



.block-title-2 {

    border-bottom: 1px solid #CECECE;

    font-size: 13px;

    font-weight: bold;

    margin: 0 0 15px;

    padding: 10px 0 0;

    position: relative;

    text-transform: uppercase;

}



.block-title-3 {

    border-bottom: 1px solid #CECECE;

    font-weight: bold;

    margin: 0 0 15px;

    padding: 10px 0 10px;

    position: relative;

    text-transform: uppercase;

}



.block-title-4 {

    display: inline;

    font-size: 20px;

    font-weight: 700;

    letter-spacing: 0.2em;

    margin: 0 auto;

    padding: 0 12px;

    text-transform: uppercase;

}



.block-title-5 {

    display: block;

    font-size: 16px;

    margin: 0 0 5px;

    font-weight: 700;

    letter-spacing: 0.2em;

    padding: 0;

    text-transform: uppercase;

}



.border-title {

    border-bottom: 1px solid #CECECE;

    margin: 0 0 15px;

    padding: 10px 0 10px;

}



/* Helper class */

.error label.error {

    color: red;

}



.section-block {

    margin-bottom: 30px;

}



.uppercase {

    text-transform: uppercase;

}



.underline {

    text-decoration: underline;

}



.relative {

    position: relative;

}



.no-margin {

    margin: 0 !important;

}



.no-padding {

    padding: 0 !important;

}



.no-margin-right {

    margin-right: 0;

}



.no-margin-left {

    margin-left: 0;

}



.no-margin-top {

    margin-top: 0;

}



.no-margin-bottom {

    margin-bottom: 0;

}



.no-border {

    border: 0;

}



.width100, .w100 {

    width: 100%;

}



.width50, .w50 {

    width: 100%;

    display: block;

}



.full-container {

    width: 100%;

    display: block;

    clear: both;

}



.float-right {

    float: right;

}



.float-left {

    float: left;

}



.maxheight100 {

    max-height: 100px;

}



.maxheight200 {

    max-height: 200px;

}



.maxheight300 {

    max-height: 300px;

}



.maxheight400 {

    max-height: 400px;

}



.maxheight500 {

    max-height: 500px;

}



.maxheight600 {

    max-height: 600px;

}



.maxheight700 {

    max-height: 700px;

}



.maxwidth100 {

    max-width: 100px;

}



.maxwidtht200 {

    max-width: 200px;

}



.maxwidth300 {

    max-width: 300px;

}



.maxwidth400 {

    max-width: 400px;

}



.maxwidth500 {

    max-width: 500px;

}



.maxwidth600 {

    max-width: 600px;

}



.maxwidth700 {

    max-width: 700px;

}



.padd {

    margin-top: 60px;

}



.globalPadding {

    padding: 50px 0;

}



.globalPaddingTop {

    padding: 50px 0 0;

}



.globalPaddingBottom {

    padding-bottom: 50px;

}



.hr2 {

    border-width: 2px;

    border-color: #DDDDDD;

}



.hr3 {

    border-width: 4px;

    border-color: #DDDDDD;

}



ul.list-dot li {

    list-style: inside disc;

}



ul.list-number li {

    list-style: inside decimal;

}



ul.list {

    list-style: disc;

    padding-left: 40px;

}



ul.list ul {

    list-style: circle;

    padding-left: 40px;

}



ul.list-check {

    list-style: none;

}



ul.list-border li {

    border-bottom: 1px solid #E0EDED;

    display: block;

    padding: 10px 15px;

}



ul.list-border li:hover {

    background: #ebebeb;

}



.transitionfx {

    transition: all 0.25s ease 0s;

    -moz-transition: all 0.25s ease 0s;

    -webkit-transition: all 0.25s ease 0s;

    -o-transition: all 0.25s ease 0s;

    -ms-transition: all 0.25s ease 0s;

}



.hw100 {

    height: 100%;

    width: 100%;

}



.display-table {

    display: table;

}



.display-table-cell {

    display: table-cell;

    vertical-align: middle;

}



/* ================================================================== */

/* CUSTOMIZE THE BOOTSTRAP MODAL + INPUT FORM ELEMENT + BUTTON + ALERT

/* ================================================================== */

.modal {

    overflow: auto;
z-index:1200 !important;
}



.signUpContent .modal-dialog {

    max-width: 460px;
    z-index:1200 !important;
}



.modal-dialog.modal-xs {

    max-width: 460px;

}



#product-details-modal .modal-dialog {

    background: #fff;

    max-width: 960px;

    overflow: auto;

    width: 100%;

}



.modal-dialog .product-share {

    border-top: 0 none;

    margin-top: 0;

}



.modal-dialog .modal-product-thumb {

    padding-top: 15px;

}



.modal-dialog {

    background: #fff;

    max-width: 960px;

    overflow: auto;

    width: 100%;

}



.modal-header {

    border-bottom: 1px solid #E5E5E5;

    color: #FFFFFF;

    padding: 5px 15px;

    margin-bottom: 20px;

}



.modal-title-site {

    color: #FFFFFF;

    font-family: Oswald;

    font-size: 38px;

    font-weight: lighter;

    padding: 13px 0;

    text-transform: uppercase;

}



.modal-content {

    border: 0 solid #ddd;

    border-radius: 0;

    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.4);

}



.modal-footer {

    background: #eee;

    border-top: 1px solid #E5E5E5;

    padding: 10px 20px;

}



.form-control:focus {

    box-shadow: NONE;

    outline: 0 none;

}



input[type="text"], input[type="password"], input[type="email"] {

    border-color: #DDDDDD;

    -webkit-border-radius: 3px;

    -moz-border-radius: 3px;

    border-radius: 3px;

    border-style: solid;

    border-width: 1px;

    color: #454545;

    font-size: 14px;

    margin-bottom: 10px;

    height: 36px;

}



/*



btn style moved to btn.css in V.5

@import (btn.css);

also less file available btn.less



*/



p.required, .required sup {

    color: #E74C3C;

}



.pagination li.active a {

    box-shadow: 0 0 9px rgba(0, 0, 0, 0.2) inset;

}



.checkbox {

    padding-left: 0;

    margin-left: 0;

}



legend {

    padding-bottom: 10px;

}



.alert {

    border: none;

    border-left: 5px solid rgba(0, 0, 0, 0.1);

    border-radius: 0;

}



.alert .close {

    font-size: 30px;

    line-height: 20px;

}



.input-group-btn .btn {

    font-size: 14px;

    padding: 8px 12px;

}



/* =============================================================== */

/* CUSTOMIZE NAVBAR

/* =============================================================== */

.navbar-toggle {

    padding:9px;
    /*background: rgba(0, 0, 0, 0.2);*/
    font-size:22px;
}



.navbar-tshop {

    width: 100%;

}



.menuWrap {

    border-top: 1px solid rgba(0, 0, 0, 0.07);

}



.brandWrap {

}



.navbar-brand {

    padding: 10px;

    color: #FFFFFF;

    font-size: 28px;

    font-weight: bold;

}



.navbar .navbar-top {

    height: 30px;

    transition: all 0.1s ease-out 0s;

    -webkit-transition: all 0.1s ease-out 0s;

    -moz-transition: all 0.1s ease-out 0s;

    -ms-transition: all 0.1s ease-out 0s;

    -o-transition: all 0.1s ease-out 0s;

    font-size: 11px;

    font-weight: bold;

    line-height: 11px;

    text-transform: uppercase;

}



.navbar.stuck .navbar-top {

    margin-top: -30px;

}



.brandWrap {

    transition: all 0.2s ease-out 0s;

    -webkit-transition: all 0.2s ease-out 0s;

    -moz-transition: all 0.2s ease-out 0s;

    -ms-transition: all 0.2s ease-out 0s;

    -o-transition: all 0.2s ease-out 0s;

    height: auto;

}



/***  ------- Mega Menu Start ------- ***/

.megamenu .nav, .megamenu .dropup, .megamenu .dropdown, .megamenu .collapse {

    position: static;

}



.megamenu .navbar-inner, .megamenu .container {

    position: relative;

}



.megamenu .dropdown-menu {

    left: auto;

}



.megamenu .dropdown-menu > li {

    display: block;

}



.megamenu .nav.pull-right .dropdown-menu {

    right: 0;

}



.megamenu .megamenu-content {

    padding: 20px 30px;

    *zoom: 1;

}



.megamenu .megamenu-content:before, .megamenu .megamenu-content:after {

    display: table;

    content: "";

    line-height: 0;

}



.megamenu .megamenu-content:after {

    clear: both;

}



.megamenu.navbar .nav > li > .dropdown-menu:after, .megamenu.navbar .nav > li > .dropdown-menu:before {

    display: none;

}



.megamenu .dropdown.megamenu-fullwidth .dropdown-menu {

    width: 100%;

    left: 0;

    right: 0;

}



.megamenu .dropdown.megamenu-80width .dropdown-menu {

    width: 80%;

    left: 0;

    right: 0;

}



.megamenu .dropdown.megamenu-80width .dropdown-menu {

    width: 80%;

    left: 0;

    right: 0;

}



.megamenu .dropdown.megamenu-50width .dropdown-menu {

    width: 50%;

    left: 0;

    right: 0;

}



.megamenu .dropdown.megamenu-40width .dropdown-menu {

    width: 40%;

    left: 0;

    right: 0;

}



.megamenu .dropdown.megamenu-fullwidth .dropdown-menu {

    width: 100%;

    left: 0;

    right: 0;

}



@media (max-width: 969px) {

    .megamenu .dropdown.megamenu-fullwidth .dropdown-menu {

        width: auto;

    }



    .megamenu .megamenu-content {

        padding-left: 0;

        padding-right: 0;

    }



    .megamenu .dropdown-menu > li > ul {

        display: block;

    }

}



/* megamenu end */



.megamenu-content img {

    max-width: 100%;

}



.megamenu-content ul li {

    border-bottom: 1px solid #EDEDED;

    margin-bottom: 5px;

    padding-bottom: 5px;

}



.megamenu-content ul li a {

    font-size: 12px;

}



.itemInMenu {

    margin-bottom: 0 !important;

}



.itemInMenu .image {

    max-height: 170px !important;

}



.margin-top-10 {

    margin-top: 10px;

}



.social-icon li {

    display: inline-block;

}



.social-icon li a {

    color: #FFFFFF;

    display: inline-block;

    font-size: 18px;

    height: auto;

    margin-left: 0;

    padding: 6px 9px;

    position: relative;

    text-align: center;

    z-index: 10;

    box-shadow: 0 0 0px rgba(0, 0, 0, 0.0) inset;

    transition: all 0.2s ease-out 0s;

    -webkit-transition: all 0.21s ease-out 0s;

    -moz-transition: all 0.2s ease-out 0s;

    -ms-transition: all 0.2s ease-out 0s;

    -o-transition: all 0.2s ease-out 0s;

}



.social-icon li a:hover {

    opacity: 0.8;

    box-shadow: 0 0 5px rgba(0, 0, 0, 0.4) inset;

}



.navbar-toggle .icon-bar {

    border: solid 2px #fff;

}



.userMenu > li {

    display: block;

    float: left;

}



.userMenu > li > a {

    color: #FFFFFF;

    display: block;

    float: right;

    font-size: 12px;

    line-height: 30px;

    padding: 0 20px 0 0;

    text-decoration: none;

    transition: opacity 0.2s ease-out 0s;

    -webkit-transition: opacity 0.21s ease-out 0s;

    -moz-transition: opacity 0.2s ease-out 0s;

    -ms-transition: opacity 0.2s ease-out 0s;

    -o-transition: opacity 0.2s ease-out 0s;

}



.userMenu > li > a:hover {

    opacity: 0.9;

}



.phone-number span {

    float: left;

}



.hasUserMenu > .dropdown-menu {

    padding-top: 0;

    padding-bottom: 0;

    left: auto !important;

    right: 0!important;

}



.hasUserMenu > .dropdown-menu > li > a {

    color: #454545;

    font-size: 12px;

    padding: 5px 15px;

}



.hasUserMenu > .dropdown-menu > li > a i {



    margin-right: 10px;

}



/***  ------- Main Navigation onhover issue for ipad and mobile ------- ***/



@media (min-width: 979px) {

    .navbar-tshop ul.nav li.dropdown ul.dropdown-menu {

    }



    .navbar-tshop ul.nav li.dropdown:hover ul.dropdown-menu, div.cartMenu.dropdown:hover div.dropdown-menu {

        display: block;

        filter: none;

        opacity: 1;

    }



    .megamenu-content ul li a {

        transition: all 0.2s ease 0s;

        -webkit-transition: all 0.2s ease 0s;

        -moz-transition: all 0.2s ease 0s;

        -ms-transition: all 0.2s ease 0s;

        -o-transition: all 0.2s ease 0s;

        display: block;

    }



    .megamenu-content ul li a:hover {

        padding-left: 5px;

    }



    .megamenu-content ul li.productPopItem a:hover, .megamenu-content ul li a.newProductMenuBlock:hover {

        padding-left: 0;

    }



    .navbar.stuck .brandWrap {

        height: 0;

        overflow: hidden;

    }

}



.navbar-nav > li > a {

    color: #FFFFFF;

    font-size: 14px;

    font-weight: 600;

    text-transform: uppercase;

    padding-top: 20px;

    height:60px;

}



.search-box {

    float: right;

    height: 48px;

    width: 38px;

}



.search-box .input-group .getFullSearch {

    padding-left: 10px;

    padding-right: 10px;



}



.search-box.static-search {

    width: auto;

    min-width: 143px;

}



.search-box.static-search #search-form {

    padding-right: 0;

}



.navbar-nav.navbar-right:last-child {

    margin-right: 0;

}



.search-box .input-group {

    margin-top: 8px;

    max-width: 34px;

    transition: all 0.3s ease-out 0s;

    -webkit-transition: all 0.3s ease-out 0s;

    -moz-transition: all 0.3s ease-out 0s;

    -ms-transition: all 0.3s ease-out 0s;

    -o-transition: all 0.3s ease-out 0s;

}



.search-box:hover .input-group {

    max-width: 183px;

}



.search-box .form-control {

    width: 0;

    padding: 0;

    height: 34px;

    border: 0;

    opacity: 0;

    transition: all 0.3s ease-out 0s;

    -webkit-transition: all 0.3s ease-out 0s;

    -moz-transition: all 0.3s ease-out 0s;

    -ms-transition: all 0.3s ease-out 0s;

    -o-transition: all 0.3s ease-out 0s;

}



.search-box:hover .form-control {

    border: solid 1px #fff;

}



.btn-nobg {

    background: none;

    color: #fff;

}



.search-box:hover .btn-nobg {

    background: #fff;

}



.search-box.static-search .input-group {

    margin-top: 1px;

    transition: all 0.3s ease-out 0s;

    -webkit-transition: all 0.3s ease-out 0s;

    -moz-transition: all 0.3s ease-out 0s;

    -ms-transition: all 0.3s ease-out 0s;

    -o-transition: all 0.3s ease-out 0s;

}



.search-box.static-search .form-control {

    width: auto;

    padding: 0;

    height: 35px;

    border: solid 1px #fff;

    opacity: 1;

    transition: all 0.3s ease-out 0s;

    -webkit-transition: all 0.3s ease-out 0s;

    -moz-transition: all 0.3s ease-out 0s;

    -ms-transition: all 0.3s ease-out 0s;

    -o-transition: all 0.3s ease-out 0s;

}



.search-box.static-search:hover .form-control {

    opacity: 1;

    border: solid 1px #fff;

    color: #333;

    background: #fff;

}



.search-box.static-search button {

    border: 0 !important;

    margin: 0 !important;

}



/***  ------- Search Bar Full ------- ***/



.search-full {

    bottom: 0;

    letter-spacing: 1px;

    min-height: 60px;

    position: absolute;

    right: 0;

    text-align: right;

    white-space: nowrap;

    width: 100%;

    z-index: 10;

    display: none;

}



.searchInputBox {

    display: block;

    max-width: 90%;

    text-align: right;

    width: 100%;

}



.search-close {

    background: rgba(0, 0, 0, 0.3);

    display: table;

    float: right;

    font-size: 32px;

    height: 51px;

    padding: 0 15px;

}



.search-close i {

    display: table-cell;

    vertical-align: middle;

    transition: all 0.5s ease 0s;

    -moz-transition: all 0.5s ease 0s;

    -webkit-transition: all 0.5s ease 0s;

    -ms-transition: all 0.5s ease 0s;

    -o-transition: all 0.5s ease 0s;

}



.search-close:hover i {

    transform: scale(1.15) rotate(-180deg);

    -webkit-transform: scale(1.1) rotate(-180deg);

    -moz-transform: scale(1.1) rotate(-180deg);

    -ms-transform: scale(1.1) rotate(-180deg);

    -o-transform: scale(1.1) rotate(-180deg);

}



.search-input {

    -moz-box-sizing: border-box;

    -webkit-box-sizing: border-box;

    border: 0 none;

    color: #FFFFFF;

    font-family: sans-serif;

    font-size: 20px;

    line-height: 1.2;

    margin: 0;

    outline: medium none;

    text-align: right;

    padding: 13px 0 13px 15px;

    width: 100%;

    max-width: 90%;

}



.search-input::-webkit-input-placeholder {

    color: rgba(255, 255, 255, 0.6);

}



.search-input::-moz-input-placeholder {

    color: rgba(255, 255, 255, 0.6);

}



.searchInputBox .search-btn {

    border: none;

    padding: 0 10px 0 0;

    color: #fff;

    color: rgba(255, 255, 255, 0.8);

}



.search-full.active {

    display: block;

}



.navbar-cart {

    border-top: 1px solid rgba(0, 0, 0, 0);

    box-shadow: 0 1px 0 rgba(255, 255, 255, 0.1) inset;

    max-height: 340px;

    overflow-x: visible;

    padding-left: 0px;

    padding-right: 0px;

}



.colorWhite, .colorWhite i {

    color: #fff !important;

}



.promo-1 {

    color: #FFFFFF;

    font-family: Oswald, Helvetica Neue;

    margin-bottom: 15px;

    font-weight: lighter;

    padding: 5px 0;

    text-align: center;

    text-transform: uppercase;

}



.promo-1sub {

    background: #F2F2F2;

    font-size: 14px;

    letter-spacing: 0.5px;

    margin-bottom: 15px;

    padding: 5px 0;

    text-align: center;

    text-transform: uppercase;

}



/***  ------- Cart Dropdown Menu Style ------- ***/



.dropdown-left .dropdown-menu {

    left: 0;

}



.cartMenu {

    float: left;

    display: block;

}



.cartMenu a.dropdown-toggle,

.cartMenu a#cart-sidebar-toggle {

    padding-bottom: 20px;

    padding-top: 20px;

    padding-left: 20px;

    padding-right: 20px;

    color: #FFFFFF;

    font-size: 14px;

    font-weight: 600;

    text-transform: uppercase;

    line-height: 20px;

    display: block;

}



.cartMenu .dropdown-menu {

    padding-bottom: 0;

    border-radius: 0;

    margin-top: 0;

}



.miniCartTable {

    max-height: 300px;

    width: 100%;

    display: block;

}



.miniCartProductThumb {

    text-align: center;

}



.cartMenu img {

    width: 50px;

}



.cartMenu h4 {

    padding-bottom: 0;

    font-size: 12px;

    font-weight: 400;

}



.miniCartDescription .size {

    color: #888888;

    font-size: 11px;

}



.miniCartDescription .price {

    color: #444444;

    font-size: 15px;

    font-weight: 700;

}



.old-price {

    color: #666666;

    font-size: 14px;

    font-weight: normal;

    text-decoration: line-through;

}



.cartMenu table {

    width: 100%;

    height: auto;

}



.cartMenu table tr {

    border-bottom: solid 1px #ddd;

}



.cartMenu table tr td {

    padding: 5px 0;

}



.miniCartFooter {

    background: #ECF0F1;

    padding: 10px 10px 10px;

}



#getAdressField .input-group {

    padding-bottom: 10px;

}



.miniCartFooter .btn:last-child {

    margin-left: 5px;



}



.miniCartFooter .subtotal {

    color: #000000;

    font-size: 16px;

    font-weight: bold;

    line-height: normal;

    margin-bottom: 0;

    text-transform: uppercase;

}



.miniCartFooterInMobile .subtotal {

    font-size: 18px;

    color: #fff;

}



.miniCartTable .mCSB_container {

    margin-right: 15px !important;

}



.newProductMenuBlock {

    display: block;

    position: relative;

}



.ProductMenuCaption {

    position: absolute;

    bottom: 0;

    color: #fff;

    text-align: center;

    width: 100%;

    padding: 5px 0;

    font-weight: 700;

    text-transform: capitalize;

    font-size: 16px;

    background: rgb(0, 0, 0);

    background: transparent \9;

    background: rgba(0, 0, 0, 0.8);

    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr=#cc000000, endColorstr=#cc000000); /* IE FIX */

    zoom: 1;

}



.ProductMenuCaption:nth-child(n) {

    filter: none;

}



.ProductMenuCaption i {

    font-size: 0;

    transition: all 0.2s ease-out 0s;

    -webkit-transition: all 0.2s ease-out 0s;

    -moz-transition: all 0.2s ease-out 0s;

    -ms-transition: all 0.2s ease-out 0s;

    -o-transition: all 0.2s ease-out 0s;

}



.newProductMenuBlock:hover i {

    font-size: 16px;

}



.newCollectionUl ul li:first-child {

}



.newCollectionUl li {

    font-weight: 600;

}



.productPopItem {

    border: solid 1px #ddd;

}



.productPopItem .productInfo {

    color: #FFFFFF;

    display: block;

    font-size: 14px;

    margin-top: -50px;

    padding: 5px 0;

    position: relative;

    text-align: center;

    width: auto;

    z-index: 4;

}



.popProImg a {

    display: block;

    max-height: 270px;

    overflow: hidden;

}



.productPop h4 {

    bottom: 0;

    color: #FFFFFF;

    font-size: 14px;

    line-height: normal !important;

    padding: 3px 0 0 !important;

    position: absolute;

    width: 100%;

    background: rgb(0, 0, 0);

    background: transparent \9;

    background: rgba(0, 0, 0, 0.8);

    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr=#cc000000, endColorstr=#cc000000); /* IE FIX */

    zoom: 1;

}



.productPop h4:nth-child(n) {

    filter: none;

}



.productPop h4 a {

    color: #fff !important;

}



/* =============================================================== */

/* HOME PAGE BOXES SLIDER 

/* =============================================================== */



.box-pagination {

    position: absolute;

    z-index: 20;

    left: 10px;

    bottom: 10px;

}



.swiper-container {

    /* background: #333; */

}



.swiper-wrapper {

    background: #fff;

}



.swiper-pagination-switch {

    display: inline-block;

    width: 8px;

    height: 8px;

    border-radius: 8px;

    background: #222;

    margin-right: 8px;

    opacity: 0.8;

    border: 1px solid #fff;

    cursor: pointer;

}



.swiper-visible-switch {

    background: #aaa;

}



.swiper-active-switch {

    background: #fff;

}



.arrow-left {

    background: url(../../images/arrows.png) no-repeat left top;

    position: absolute;

    left: 10px;

    top: 50%;

    margin-top: -15px;

    width: 17px;

    height: 30px;

    z-index: 10;

}



.arrow-right {

    background: url(../../images/arrows.png) no-repeat left bottom;

    position: absolute;

    right: 10px;

    top: 50%;

    margin-top: -15px;

    width: 17px;

    height: 30px;

    z-index: 10;

}



.swiper-container {

    width: 100%;

    height: 100%;

    height: 490px;

    color: #fff;

    text-align: center;

}



.swiper-slide {

    height: 100%;

}



.swiper-slide .slider-content {

    height: 100%;

    display: block;

}



.slide-2x {

    width: 500px;

}



.slide-4x {

    width: 500px;

}



.slide-1x {

    width: 300px;

}



.box-4in {

    display: block;

    float: left;

    height: 50%;

    width: 50%;

}



.slider-box-bottom .box-4in, .slider-box-top .box-4in {

    height: 100%;

}



.box-4in .price, .box-text-table .dealprice {

    display: block;

    font-family: oswald, Helvetica Neue, Arial, Helvetica, sans-serif;

    font-size: 26px;

    font-weight: lighter;

    margin-bottom: 15px;

}



.box-4in .btn {

    min-width: 100px !important;

}



.box-4in.a .box-content-overly, .box-4in.b .box-content-overly {

    bottom: 5px;

    right: 5px;

    top: auto;

}



.box-slider-content {

    display: block;

    height: 100%;

    width: 100%;

    position: relative;

    overflow: hidden;

}



.box-text {

    bottom: 100px;

    display: block;

    height: auto;

    left: 18%;

    position: absolute;

    width: 64%;

    z-index: 10;

}



.box-text.bottom-align {

    bottom: 20px;

}



.box-text h1, .box-text-cell-inner h1 {

    font-size: 36px;

    letter-spacing: 3px;

    font-family: Oswald, Helvetica Neue, Helvetica, Arial, sans-serif;

    font-weight: normal;

    text-transform: uppercase;

}



.box-text p, .box-text-cell-inner p {

    font-family: Oswald;

    font-weight: lighter;

}



.box-text .btn, .box-text-cell-inner .btn {

    margin-top: 10px;

}



.box-text-table {

    display: table;

    height: 100%;

    width: 100%;

    position: absolute;

    z-index: 10;

}



.box-text-cell {

    display: table-cell;

    height: 100%;

    width: 100%;

    vertical-align: middle;

}



.box-text-cell-inner.dark {

    color: #454545;

}



.box-text-cell-inner {

    max-width: 70%;

    display: block;

    margin-left: 15%;

}



.box-content-overly {

    background: rgba(0, 0, 0, 0);

    height: 100%;

    position: absolute;

    right: 5px;

    top: 0;

    visibility: hidden;

    transition: all 0.2s ease 0s;

    width: 100%;

    z-index: 5;

    transition: all 0.2s ease 0s;

    -webkit-transition: all 0.2s ease 0s;

    -moz-transition: all 0.2s ease 0s;

    -ms-transition: all 0.2s ease 0s;

    -o-transition: all 0.2s ease 0s;

}



.box-content-overly.box-content-overly-white {

    opacity: 0;

}



.box-blank-url {

    display: block;

    height: 100%;

    width: 100%;

}



.slider-box-top .box-content-overly {

    bottom: 5px;

    top: auto;

}



.box-slider-content:hover .box-content-overly {

    background: rgba(0, 0, 0, 0.3);

    visibility: visible;

}



.box-slider-content:hover .box-content-overly-white {

    background: rgba(255, 255, 255, 0.8);

    visibility: visible;

    opacity: 1;

}



.box-slider-content .box-content-overly-white .box-text, .box-slider-content .box-content-overly-white .box-text-cell-inner {

    opacity: 0;

    transition: all 0.2s ease 0s;

    -webkit-transition: all 0.2s ease 0s;

    -moz-transition: all 0.2s ease 0s;

    -ms-transition: all 0.2s ease 0s;

    -o-transition: all 0.2s ease 0s;

}



.box-slider-content:hover .box-content-overly-white .box-text, .box-slider-content:hover .box-content-overly-white .box-text-cell-inner {

    opacity: 1;

    display: block;

    color: #454545;

}



.box-slider-content .box-img {

    height: 100%;

    display: block;

    position: relative;

    z-index: 4;

    margin: 0 5px 0 0;

}



.box-img.last-child-slide {

    margin-right: 0;

}



.box-slider-content a.box-img img {

    width: 100%;

    min-height: 100%;

    height: auto;

}



.box-slider-content .slider-box-top a.box-img {

    bottom: 5px;

    height: 100%;

    overflow: hidden;

}



.box-4in.a a.box-img, .box-4in.b a.box-img {

    bottom: 5px;

    height: 100%;

    display: block;

    overflow: hidden;

}



.slider-box-top {

    width: 100%;

    height: 50%;

    display: block;

    overflow: hidden;

}



.slider-box-top .box-img {

    bottom: 5px;

}



.slider-box-bottom {

    width: 100%;

    height: 50%;

    display: block;

    overflow: hidden;

}



h1.bolder-style {

    font-size: 80px;

    font-weight: 800;

    line-height: 80px;

    padding-bottom: 0;

    text-shadow: 0 1px 3px rgba(0, 0, 0, 0.8), 0 0 1px #ffffff;

    -webkit-text-shadow: 0 1px 3px rgba(0, 0, 0, 0.8), 0 0 1px #ffffff;

    text-transform: uppercase;

}



h1.bolder-style.light, h1.bolder-style.light a {

    color: #fff;

}



h1.bolder-style.dark, h1.bolder-style.dark a {

    color: #454545;

    text-shadow: 0 1px 3px rgba(255, 255, 255, 0.8), 0 0 1px #454545;

    -webkit-text-shadow: 0 1px 3px rgba(255, 255, 255, 0.8), 0 0 1px #454545;

}



.slider-box-top .bolder-sub a {

    color: #fff;

    font-size: 16px;

    font-weight: 400;

    letter-spacing: 3.5px;

}



.box-price-tag {

    position: absolute;

    right: 50px;

    bottom: 50px;

    z-index: 10;

}



.box-price-tag .price {

    background: #fff;

    color: #454545;

    font-family: oswald;

    font-size: 28px;

    font-weight: normal;

    letter-spacing: 2px;

    padding: 0 10px;

}



@media (max-width: 991px) {

    .swiper-container {

        height: 490px;

    }



    .slide-2x, .slide-4x, .slide-1x {

        width: 490px;

    }



    .box-text {

        bottom: 10% !important;

    }

}



@media (max-width: 767px) {

    .swiper-container {

        height: 350px;

    }



    .slide-2x, .slide-4x, .slide-1x {

        width: 350px;

    }



    .box-text {

        bottom: 10%;

    }



    .box-text h1, .box-text-cell-inner h1 {

        font-size: 26px;

    }



    .btn-stroke-dark, .btn-stroke-light {

        padding: 10px 20px;

        min-width: 150px;

    }



    h1.bolder-style {

        font-size: 50px;

        line-height: 50px;

    }

}



/* =============================================================== */

/* HOME PAGE SLIDER  

/* =============================================================== */



.sliderImg {

    background: url('../../images/site/loading1.gif') center center no-repeat; /* prealoder */

}



.banner {

    /*margin-top: 80px;*/

    -webkit-transition: all 0.1s ease-out 0s;

    -moz-transition: all 0.1s ease-out 0s;

    -ms-transition: all 0.1s ease-out 0s;

    -o-transition: all 0.1s ease-out 0s;

    transition: all 0.1s ease-out 0s;

}



.banner-boxes {

    margin-top: 80px;

}



.banner.down {

    margin-top: 26px;

}



.banner, .slider-content, .slider, .slider-v2 {

    width: 100%;

    display: block;

    max-height: 560px;

    height: auto;

    overflow: hidden;

    position: relative;

}



.slider-item {

    width: 100%;

    height: 100%;

    position: relative;

    overflow: hidden;

}



.sliderImg {

    position: relative;

    top: 0;

    left: 0;

    z-index: 1;

}



.banner .sliderInfo {

    position: absolute;

    width: 100%;

    height: 700px;

    z-index: 2;

}



.banner .sliderInfo .container {

    height: 100%;

    dispay: block;

}



/*.sliderText {

    display: block;

    -webkit-transform: translateX(-120px);

    -moz-transform: translateX(-120px);

    -o-transform: translateX(-120px);

    -ms-transform: translateX(-120px);

    transform: translateX(-120px);

    -webkit-transition: opacity .5s linear, -webkit-transform .7s cubic-bezier(.56, .48, 0, .99);

    -moz-transition: opacity .5s linear, -moz-transform .7s cubic-bezier(.56, .48, 0, .99);

    -o-transition: opacity .5s linear, -o-transform .7s cubic-bezier(.56, .48, 0, .99);

    -ms-transition: opacity .5s linear, -ms-transform .7s cubic-bezier(.56, .48, 0, .99);

    transition: opacity .5s linear, transform .7s cubic-bezier(.56, .48, 0, .99);

    opacity: 0;

    filter: alpha(opacity=0); /* IE FIX */

    /*-ms-filter: "alpha(Opacity=0)";

}*/



.sliderText:nth-child(n) {

    filter: none;

}



.slider-item.cycle-slide-active .sliderText {

    -webkit-transform: translateX(0);

    -moz-transform: translateX(0);

    -o-transform: translateX(0);

    -ms-transform: translateX(0);

    transform: translateX(0);

    opacity: 1;

    -ms-filter: none;

    filter: none;

}



.transformRight {

    display: block;

    -webkit-transform: translateX(120px);

    -moz-transform: translateX(120px);

    -o-transform: translateX(120px);

    -ms-transform: translateX(120px);

    transform: translateX(120px);

    -webkit-transition: opacity .5s linear, -webkit-transform .7s cubic-bezier(.56, .48, 0, .99);

    -moz-transition: opacity .5s linear, -moz-transform .7s cubic-bezier(.56, .48, 0, .99);

    -o-transition: opacity .5s linear, -o-transform .7s cubic-bezier(.56, .48, 0, .99);

    -ms-transition: opacity .5s linear, -ms-transform .7s cubic-bezier(.56, .48, 0, .99);

    transition: opacity .5s linear, transform .7s cubic-bezier(.56, .48, 0, .99);

    opacity: 0;

    filter: alpha(opacity=0); /* IE FIX */

    -ms-filter: "alpha(Opacity=0)";

}



.slider-item.cycle-slide-active .transformRight {

    -webkit-transform: translateX(0);

    -moz-transform: translateX(0);

    -o-transform: translateX(0);

    -ms-transform: translateX(0);

    transform: translateX(0);

    opacity: 1;

    -ms-filter: none; /* IE FIX */

    filter: none;

}



.transformLeft {

    display: block;

    -webkit-transform: translateX(-120px);

    -moz-transform: translateX(-120px);

    -o-transform: translateX(-120px);

    -ms-transform: translateX(-120px);

    transform: translateX(-120px);

    -webkit-transition: opacity .5s linear, -webkit-transform .7s cubic-bezier(.56, .48, 0, .99);

    -moz-transition: opacity .5s linear, -moz-transform .7s cubic-bezier(.56, .48, 0, .99);

    -o-transition: opacity .5s linear, -o-transform .7s cubic-bezier(.56, .48, 0, .99);

    -ms-transition: opacity .5s linear, -ms-transform .7s cubic-bezier(.56, .48, 0, .99);

    transition: opacity .5s linear, transform .7s cubic-bezier(.56, .48, 0, .99);

    opacity: 0;

    filter: alpha(opacity=0); /* IE FIX */

    -ms-filter: "alpha(Opacity=0)";

}



.slider-item.cycle-slide-active .transformLeft {

    -webkit-transform: translateX(0);

    -moz-transform: translateX(0);

    -o-transform: translateX(0);

    -ms-transform: translateX(0);

    transform: translateX(0);

    opacity: 1;

    -ms-filter: none;

    filter: none;

}



.sliderText .inner {

    padding: 20px;

}



.sliderText h1 {

    font-size: 36px;

    font-weight: lighter;

}



.sliderText.dark h1 {

    color: #fff;

    text-shadow: 0 1px 2px rgba(0, 0, 0, 0.6);

}



.slide-link {

    color: #fff;

    letter-spacing: -0.25px;

    font-size: 16px;

}



.sliderText {

    margin-top: 15%;

}



.sliderText.dark {

    color: #ddd;

}



.slider-item-img1 {

    background-size: cover;

}



.slider-item-img2 {

    background-size: cover;

}



.slider-item-img3 {

    background-size: cover;

}



/***  ------- Slider Style 1 Start ------- ***/



#pager2 {

    position: absolute;

    bottom: 10px;

    z-index: 100;

    width: 100%;

    z-index: 200;

    margin: 0 auto;

    padding: 0;

    text-align: center;

}



#pager2 span {

    background: rgba(0, 0, 0, 0) !important;

    -webkit-border-radius: 50%;

    -moz-border-radius: 50%;

    border-radius: 50%;

    cursor: pointer;

    display: inline-block;

    height: 16px;

    margin: 5px;

    text-indent: -9999px;

    width: 16px;

}



#pager2 span.cycle-pager-active {

    color: #fff;

}



.sliderControl {

    position: absolute;

    z-index: 200;

    top: 45%;

    display: inline-block;

    cursor: pointer;

    background: #fff;

    -webkit-border-radius: 2px;

    -moz-border-radius: 2px;

    border-radius: 2px;

    color: #454545;

    padding: 0 5px;

}



.sliderControl {

    opacity: 1;

}



.sliderControl:hover:nth-child(n) {

    filter: none;

}



.sliderControl:hover {

    background: rgb(255, 255, 255);

    background: transparent \9;

    background: rgba(255, 255, 255, 0.6);

    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr=#99ffffff, endColorstr=#99ffffff); /* IE FIX */

    zoom: 1;

}



.prevControl {

    left: 20px;

    -webkit-transform: translateX(-50px);

    -moz-transform: translateX(-50px);

    -o-transform: translateX(-50px);

    -ms-transform: translateX(-50px);

    transform: translateX(-50px);

    -webkit-transition: opacity .5s linear, -webkit-transform .7s cubic-bezier(.56, .48, 0, .99);

    -moz-transition: opacity .5s linear, -moz-transform .7s cubic-bezier(.56, .48, 0, .99);

    -o-transition: opacity .5s linear, -o-transform .7s cubic-bezier(.56, .48, 0, .99);

    -ms-transition: opacity .5s linear, -ms-transform .7s cubic-bezier(.56, .48, 0, .99);

    transition: opacity .5s linear, transform .7s cubic-bezier(.56, .48, 0, .99);

    opacity: 0;

    filter: alpha(opacity=0);

    -ms-filter: "alpha(Opacity=0)";

}



.banner:hover .prevControl {

    -webkit-transform: translateX(0);

    -moz-transform: translateX(0);

    -o-transform: translateX(0);

    -ms-transform: translateX(0);

    transform: translateX(0);

    opacity: 1;

    -ms-filter: none;

    filter: none;

}



.nextControl {

    right: 20px;

    -webkit-transform: translateX(50px);

    -moz-transform: translateX(50px);

    -o-transform: translateX(50px);

    -ms-transform: translateX(50px);

    transform: translateX(50px);

    -webkit-transition: opacity .5s linear, -webkit-transform .7s cubic-bezier(.56, .48, 0, .99);

    -moz-transition: opacity .5s linear, -moz-transform .7s cubic-bezier(.56, .48, 0, .99);

    -o-transition: opacity .5s linear, -o-transform .7s cubic-bezier(.56, .48, 0, .99);

    -ms-transition: opacity .5s linear, -ms-transform .7s cubic-bezier(.56, .48, 0, .99);

    transition: opacity .5s linear, transform .7s cubic-bezier(.56, .48, 0, .99);

    opacity: 0;

    filter: alpha(opacity=0);

    -ms-filter: "alpha(Opacity=0)";

}



.banner:hover .nextControl {

    -webkit-transform: translateX(0);

    -moz-transform: translateX(0);

    -o-transform: translateX(0);

    -ms-transform: translateX(0);

    transform: translateX(0);

    opacity: 1;

    -ms-filter: none;

    filter: none;

}



.sliderText.white {

    background: rgb(255, 255, 255);

    background: transparent \9;

    background: rgba(255, 255, 255, 0.8);

    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr=#ccffffff, endColorstr=#ccffffff); /* IE FIX */

    zoom: 1;

}



.sliderText.white:nth-child(n) {

    filter: none;

}



.sliderText.white h1 {

}



.sliderText h3.price {

    font-weight: lighter;

    font-size: 22px;

}



.sliderTextFull {

    color: #ffffff;

    top: 50%;

    margin: 0 !important;

    transform: translateY(-50%);

    -webkit-transform: translateY(-50%);

}



.sliderTextFull h3 {

    font-size: 24px;

    line-height: 32px;

    margin-top: 0;

}



.topAnima {

    opacity: 0;

}



.cycle-slide-active .topAnima {

    opacity: 1;

    animation-name: fadeInUp;

    -webkit-animation-name: fadeInUp;

    animation-duration: 1s;

    animation-delay: 0.3s;

    visibility: visible !important;

}



.sliderTextFull .opacity0 {

    opacity: 0 !important;

}



.cycle-slide-active .sliderTextFull .bottomAnima {

    opacity: 1 !important;

    animation-name: fadeInDown;

    -webkit-animation-name: fadeInDown;

    animation-duration: 1s;

    animation-delay: 0.4s;

    -webkit-animation-duration: 1s;

    visibility: visible !important;

}



.sliderTextFull .inner.dark {

    display: block;

    margin: 0 auto;

    padding: 20px 10px;

    width: auto;

    background: rgb(0, 0, 0);

    background: transparent \9;

    background: rgba(0, 0, 0, 0.8);

    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr=#cc000000, endColorstr=#cc000000); /* IE FIX */

    zoom: 1;

}



.sliderTextFull .inner.dark:nth-child(n) {

    filter: none;

}



.blankstyle h1 {

    display: block;

    font-size: 36px;

    font-weight: bold;

    height: auto;

    letter-spacing: -1px;

    line-height: 36px;

    opacity: 1;

    padding: 0;

    text-transform: uppercase;

}



.color-white h1, .color-white p, .color-white {

    color: #fff;

}



.color-black h1, .color-black p, .color-black {

    color: #000;

}



/***  ------- Slider Style 2 Pager ------- ***/



#pager {

    position: absolute;

    bottom: 0;

    z-index: 100;

    width: 100%;

    z-index: 200;

    margin: 0 auto;

    padding: 0;

}



#pager a {

    color: #fff;

    display: block;

    text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.4);

    float: left;

    font-family: Oswald, Helvetica Neue;

    font-size: 15px;

    font-weight: normal;

    list-style: none outside none;

    padding: 10px 0;

    text-align: center;

    text-transform: uppercase;

    text-decoration: none;

    width: 33.333334%;

    background: rgba(0, 0, 0, 0.5);

}



#pager a.cycle-pager-active {

    color: #fff;

}



/*  // Style   2  end*/



/* =============================================================== */

/* HOME PAGE LOOK 2  

/* =============================================================== */



.home-intro h2 {

    font-size: 20px;

    font-weight: 200;

    letter-spacing: 2px;

    text-align: center;

    text-transform: uppercase;

    max-width: 90%;

    margin-left: auto;

    margin-right: auto;

    line-height: 24px;

}



.home-intro {

    background: #34373b;

    color: #fff;

    padding: 15px 0 0;

}



.home-intro h2 span {

    color: #ff7f00;

}



.sectionCategory {

    background: #34373b;

    padding: 60px 0;

    position: relative;

}



.sectionCategoryIntro {

    color: #fff;

    max-width: 600px;

    margin: 0 auto;

}



.sectionCategory .container {

    position: relative;

    z-index: 5;

}



.sectionCategoryIntro h1 {

    font-family: Oswald;

    font-size: 22px;

    font-weight: lighter;

    letter-spacing: 2px;

    margin-bottom: 0;

    padding-bottom: 5px;

    text-transform: uppercase;

}



.sectionCategoryIntro p {

    color: #ddd;

}



.sectionCategory .subCategoryThumb {

    height: 160px !important;

}



/***  -------  Home look 2 || productShowCase section  ------- ***/



.owl-theme .owl-controls .owl-page span {

    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.4) inset;

}



.show-case-wrapper {

    margin: 0 auto 30px;

    float: none;

}



#productShowCase .owl-controls {

    margin-top: 0;

}



.product-slide-inner {

    margin-top: 12%;

    display: block;

    max-width: 98%;

}



@media (max-width: 767px) {

    .product-slide-inner {

        display: block;

        max-width: 98%;

        margin: 5% auto 0;

        text-align: center;

    }



    #productShowCase .owl-controls {

        margin-top: 10px;

    }

}



#productShowCase .btn-stroke-dark i {

    font-size: 0;

    transition: font 0.3s ease;

}



#productShowCase .btn-stroke-dark:hover i {

    font-size: inherit;

}



#productShowCase .details-description {

    margin-bottom: 15px;

}



.product-slide-left {

    text-align: center;

}



.product-slide-img {

    display: block;

    text-align: center;

}



.product-slide-img img {

    display: block;

    text-align: center;

    margin: 0 auto

}



#productShowCase .product-title {

    font-family: oswald;

    font-size: 24px;

    font-weight: normal;

    letter-spacing: 0.25px;

    line-height: 36px;

    margin-bottom: 0;

}



.ps-nav {

    display: block;

    font-size: 68px;

    height: auto;

    position: absolute;

    text-align: center;

    top: 38%;

    width: auto;

    text-shadow: 0 1px 0 rgba(0, 0, 0, 0.25);

}



#ps-next {

    right: 15px;

}



#ps-prev {

    left: 15px;

}



@media (max-width: 767px) {

    #ps-next {

        right: 15px;

    }



    #ps-prev {

        left: 15px;

    }

}



.boxes-title-1 {

    background: #ebebeb;

    font-family: Oswald;

    font-weight: normal;

    letter-spacing: 1px;

    margin-bottom: 30px;

    padding: 7px 0;

    text-align: center;

}



/***  -------  Home look 2 || Featured image section  ------- ***/



.featuredImgLook2 .inner {

    border: solid 1px #ddd;

    padding: 10px;

    margin-bottom: 15px;

}



.featuredImgLook2 .inner:hover {

    border: solid 1px #4EC67F;

}



.img-link {

    display: block;

    overflow: hidden;

}



.img-link img {

    width: 100%;

}



.featuredImgLook2 h3 {

    color: #454545;

    font-weight: 600;

    padding: 20px 0;

    text-transform: uppercase;

}



.featuredImgLook2 h3 span {

    color: #eb5e58;

    display: block;

    font-size: 14px;

    font-weight: lighter;

    letter-spacing: 1px;

    text-transform: uppercase;

}



.featuredImgLook2 .btn-link {

    border-radius: 0;

    color: #7f8c8d;

    cursor: pointer;

    font-weight: normal;

    text-transform: uppercase;

}



.featuredImgLook2 p {

    color: #7f8c8d;

    margin-bottom: 20px;

}



@media (max-width: 991px) {

    .featuredImgLook2 .col-md-3 {

        margin-bottom: 30px;

    }

}



@media (max-width: 460px) {

    .featuredImgLook2 .col-md-3 {

        width: 100%;

    }

}



/* ---------- // Home look 2 end //--------*/



/* =============================================================== */

/* HOME PAGE LOOK 3  

/* =============================================================== */



#imageShowCase {

    display: block;

    clear: both;

}



#imageShowCase .product-slide h1 {

    text-transform: uppercase;

}



#imageShowCase .product-slide .box-content-overly.box-content-overly-white {

    opacity: 1;

}



.featuredImageLook3 .inner {

    display: block;

    position: relative;

    margin-bottom: 30px;

}



.image-show-case-wrapper .ps-nav {

    opacity: 0;

    transform: scale(1.15);

    -moz-transition: all 0.3s ease 0s;

    -webkit-transition: all 0.3s ease 0s;

    -ms-transition: all 0.3s ease 0s;

    -o-transition: all 0.3s ease 0s;

}



.image-show-case-wrapper:hover .ps-nav {

    transform: scale(1);

    opacity: 1;

    -webkit-transform: scale(1);

    -moz-transform: scale(1);

    -ms-transform: scale(1);

    -o-transform: scale(1);

}



.featuredImageLook3 .inner .box-content-overly-white {

    right: 0;

    text-align: center;

}



.submini {

    height: 2px;

    max-width: 50px;

    margin: 15px auto;

    background: #454545;

    box-shadow: none;

    border: none;

}



.featuredImageLook3 .inner .img-title {

    color: white;

    display: block;

    font-size: 20px;

    font-family: Oswald;

    font-weight: normal;

    letter-spacing: 3px;

    padding: 0 10px;

    position: absolute;

    text-align: center;

    text-shadow: 1px 0px 2px #555;

    text-transform: uppercase;

    top: 40%;

    width: 100%;

    z-index: 100;

    transition: opacity .2s linear, visibility .2s linear;

}



.featuredImageLook3 .inner:hover .img-title {

    visibility: hidden;

    opacity: 0;

}



.img-block {

    display: block;

    overflow: hidden;

}



.featuredImageLook3 .inner .box-text-cell-inner h1 {

    font-size: 20px;

}



.featuredImageLook3 .inner:hover .box-content-overly-white {

    opacity: 1;

    background: rgba(255, 255, 255, 0.8);

    visibility: visible;

}



.image-show-case-wrapper .owl-pagination {

    margin-top: -40px !important;

    position: relative;

    text-align: center;

    z-index: 50;

    margin-bottom: 40px;

}



.product-slide .box-content-overly {

    text-align: center;

    visibility: visible;

    color: #fff;

}



@media (max-width: 650px) {

    .featuredImageLook3 .inner .box-text-cell-inner h1 {

        font-size: 18px;

        margin-bottom: 0;

        padding-bottom: 0;

    }



    .featuredImageLook3 .inner .box-text-cell-inner p {

        margin-bottom: 0;

        padding-bottom: 0;

        font-size: 14px;

    }



    hr.submini {

        margin-top: 5px;

        margin-bottom: 5px;

    }



    .featuredImageLook3 .inner .img-title {

        font-size: 18px;

        letter-spacing: 2px;

    }

}



@media (max-width: 500px) {

    .featuredImageLook3 .box-content-overly {

        display: none;

    }



    .featuredImageLook3 .inner .img-title {

        font-size: 16px;

        letter-spacing: 1px;

    }

}



/* ---------- // Home look 3 end //--------*/



/* =============================================================== */

/* MAIN CONTAINER || TOP CONTAINER START (NEW ARRIVALS)

/* =============================================================== */



.section-block {

    margin-bottom: 30px;

}



.section-title {

    border-bottom: 2px solid #DDDDDD;

    border-top: 2px solid #DDDDDD;

    font-size: 22px;

    line-height: 24px;

    margin: 10px 0 10px;

    padding: 0;

}



.section-title span {

    color: #454545;

    display: inline-block;

    padding: 5px 15px 5px 0;

    text-transform: uppercase;

    width: auto;

}



/***  -------  Style 2  ------- ***/



.style2 .section-title, .section-title.style2 {

    font-size: 42px;

    font-weight: lighter;

    line-height: 44px;

    margin: 10px 0 20px;

    padding: 0;

    position: relative;

    border: none;

}



.section-title-style2, .section-title.style2 {

    position: relative;

}



.style2 .section-title:after, .section-title-style2:after, .section-title.style2:after, .section-title-style2:after {

    background: #DDDDDD;

    content: "";

    display: block;

    height: 1px;

    margin-left: 20%;

    position: absolute;

    top: 28px;

    width: 60%;

    z-index: 1;

}



.section-title-style2 span, .section-title.style2 span {

    background: #FFFFFF;

    display: inline-block;

    padding: 10px;

    position: relative;

    width: auto;

    z-index: 2;

}



.style2 .section-title span, .section-title.style2 span, {

    color: #454545;

    display: table;

    position: relative;

    z-index: 2;

    background: #fff;

    padding: 5px 15px;

    text-align: center;

    text-transform: uppercase;

    width: auto;

    margin: 0 auto;

}



.productslider {

    width: 100%;

    display: block;

}



.productslider .item {

    margin: 0 15px 15px;

}



.item {

    display: block;

    height: auto;

    transition: all 0.3s ease 0s;

    -moz-transition: all 0.3s ease 0s;

    -webkit-transition: all 0.3s ease 0s;

    -o-transition: all 0.3s ease 0s;

    -ms-transition: all 0.3s ease 0s;

    margin-bottom: 15px;

    min-height: 80px;

}



#SimilarProductSlider .item {

    height: auto !important;

}



.product {

    display: block;

    height: auto;

    transition: all 0.3s ease 0s;

    -moz-transition: all 0.3s ease 0s;

    -webkit-transition: all 0.3s ease 0s;

    -o-transition: all 0.3s ease 0s;

    -ms-transition: all 0.3s ease 0s;

    background-color:#fff;

    text-align: center;

    border-radius: 0px;
}



.product:hover {

    background: #fff;
    box-shadow: 0 0px 5px rgba(0, 0, 0, 0.3);
}



.add-fav {

    border: 1px solid;

    border-radius: 50%;

    height: 30px;

    line-height: 31px;

    position: absolute;

    right: 30px;

    text-align: center;

    top: 15px;

    width: 30px;

    z-index: 10;

    transform: scale(1.15);

    -webkit-transform: scale(1.1);

    -moz-transform: scale(1.1);

    -ms-transform: scale(1.1);

    -o-transform: scale(1.1);

    -webkit-transition: opacity .3s linear, -webkit-transform .7s cubic-bezier(.56, .48, 0, .99);

    -moz-transition: opacity .3s linear, -moz-transform .7s cubic-bezier(.56, .48, 0, .99);

    -o-transition: opacity .3s linear, -o-transform .7s cubic-bezier(.56, .48, 0, .99);

    -ms-transition: opacity .3s linear, -ms-transform .7s cubic-bezier(.56, .48, 0, .99);

    transition: opacity .3s linear, transform .7s cubic-bezier(.56, .48, 0, .99);

    opacity: 0;

    filter: alpha(opacity=0);

    -ms-filter: "alpha(Opacity=0)";

}



.list-view .product .add-fav {

    right: 40px;

    top: 8px;

}



.product:hover .add-fav {

    opacity: 1;

    webkit-transform: scale(1);

    -moz-transform: scale(1);

    -o-transform: scale(1);

    -ms-transform: scale(1);

    transform: scale(1);

}



.add-fav i {

    webkit-transform: scale(1) rotate(0deg);

    -moz-transform: scale(1) rotate(0deg);

    -o-transform: scale(1) rotate(0deg);

    -ms-transform: scale(1) rotate(0deg);

    transform: scale(1) rotate(0deg);

    transition: all .3s ease;

}



.add-fav:active i {

    webkit-transform: scale(0.85) rotate(0deg);

    -moz-transform: scale(0.85) rotate(4deg);

    -o-transform: scale(0.85) rotate(0deg);

    -ms-transform: scale(0.85) rotate(0deg);

    transform: scale(0.85) rotate(4deg);

}



.item.list-view .item h4 {

    max-height: none;

}



.item.list-view .grid-description {

    display: none;

}



.list-description {

    display: none;

}



.item.list-view .list-description {

    display: block;

}



.item.list-view {

    display: block;

    height: auto !important;

    width: 100% !important;

    overflow: hidden;

    width: 100%;

    margin-bottom: 20px;

}



.item.list-view .image {

    display: block;

    float: left;

    overflow: hidden;

    position: relative;

    text-align: center;

    max-height: 260px;

    max-width: 200px;

    transition: all 0.5s ease 0s;

    -moz-transition: all 0.5s ease 0s;

    -webkit-transition: all 0.5s ease 0s;

    -o-transition: all 0.5s ease 0s;

    -ms-transition: all 0.5s ease 0s;

    padding-right: 20px;

    background: #fff;

}



.item.list-view .image a {

    display: block;

    max-height: 100%;

    height: 100%;

    max-height: 100% !important;

}



.item.list-view .image img {

    max-height: 260px;

    width: auto !important;

}



.item.list-view h4 {

    height: auto;

    margin: 45px 0 5px;

    font-size: 24px;

}



.item.list-view h4 a {

    font-weight: 600;

}



.item.list-view .description {

    text-align: right;

}



.item.list-view .description p {

    text-align: right;

    max-height: 200px;

    min-height: 10px;

}



.item.list-view .price {

    font-size: 22px;

    text-align: right;

}



.item.list-view .action-control {

    text-align: right;

}



.item.list-view .product {

    padding-right: 20px;

    min-height: 260px;

}



.item:hover .action-control {

    border-bottom-color: #fff;

}



.product-box .item {

    margin: 0 10px;

}



.product-box .item:hover {

    box-shadow: 0 0 25px rgba(0, 0, 0, 0.15);

    background: #fff;

}



.image {

    display: block;

    overflow: hidden;

    position: relative;

    text-align: center;

    width: 100%;

    max-height: 240px;

    transition: all 0.5s ease 0s;

    -moz-transition: all 0.5s ease 0s;

    -webkit-transition: all 0.5s ease 0s;

    -ms-transition: all 0.5s ease 0s;

    -o-transition: all 0.5s ease 0s;

}



.image a {

    display: block;

    overflow: hidden;

}



.image a img {

    transition: all 0.5s ease 0s;

    -moz-transition: all 0.5s ease 0s;

    -webkit-transition: all 0.5s ease 0s;

    -ms-transition: all 0.5s ease 0s;

    -o-transition: all 0.5s ease 0s;

    max-height: 240px;

    margin: 0 auto;

}



.product:hover .image a img {

    transform: scale(1.15) rotate(-1.5deg);

    -webkit-transform: scale(1.1) rotate(-1.5deg);

    -moz-transform: scale(1.1) rotate(-1.5deg);

    -ms-transform: scale(1.1) rotate(-1.5deg);

    -o-transform: scale(1.1) rotate(-1.5deg);

}



.promotion {

    position: absolute;

    z-index: 10;

    bottom: 20px;

    left: 0;

    height: 24px;

    width: auto;

    -webkit-border-radius: 4px;

    -moz-border-radius: 4px;

    -o-border-radius: 4px;

    -ms-border-radius: 4px;

    border-radius: 4px;

}



.description {

    margin: 0 auto;

    min-height: 147px;

    max-width: 235px;

}



.list-view .description {

    margin: 0 auto;

    min-height: auto;

    max-width: 100%;

}



.promotion {

    line-height: 18px;

}



.new-product {

    color: #FFFFFF;

    display: inline-block;

    float: left;

    font-family: Oswald, Helvetica Neue, Helvetica, Arial, sans-serif;

    font-size: 16px;

    font-weight: lighter;

    height: 100%;

    padding: 3px 10px;

}



.discount {

    background: #eb5e58; /* old browser */

    border-radius: 0 4px 4px 0;

    color: #fff;

    display: inline-block;

    float: left;

    font-family: Oswald, Helvetica Neue, Helvetica, Arial, sans-serif;

    font-size: 16px;

    font-weight: lighter;

    height: 100%;

    padding: 3px 5px;

}



.item h4, .item h3 {

    margin: 0;

    min-height: 45px;

    max-height: 100px;

    overflow: hidden;

    display: block;

    line-height: 20px;

    margin: 25px 0 0;

    padding: 0;

}



.item h4 a, .item h3 a {

    font-family: 'Source Sans Pro', sans-serif;

    font-weight: 700;

    color: #3a3a3a;

    text-decoration: none;

    text-transform: uppercase;

    font-size: 20px;



}



.description p {

    color: #888888;

    font-size: 14px;

    min-height: 55px;

    max-height: 90px;

    overflow: hidden;

    line-height: 18px;

}



.price {

    font-size: 18px;

    font-weight: bold;

    color: #454545;

}


.cardPrice {

    font-size: 30px;

    font-weight: bold;

    color: #454545;

}



.action-control {

    border: 0;

    display: block;

    height: auto;

    padding: 5px 0 15px;

    width: 100%;

}



.action-control a {

    cursor: pointer;

}



.cart a {

    font-size: 20px;

    color: #454545;

    margin: 0 0;

    display: inline-block;

    transition: all 0.3s ease-out 0s;

    -webkit-transition: all 0.3s ease-out 0s;

    -moz-transition: all 0.13s ease-out 0s;

    -ms-transition: all 0.3s ease-out 0s;

    -o-transition: all 0.3s ease-out 0s;

}



.load-more-block {

    padding-bottom: 30px;

    position: relative;

}



.load-more-block .btn-thin {

    background: #fff;

    z-index: 2;

    position: relative;

    box-shadow: -4px 0 0 #FFFFFF, 4px 0 0 #FFFFFF;

    -webkit-box-shadow: -4px 0 0 #FFFFFF, 4px 0 0 #FFFFFF;

    -moz-box-shadow: -4px 0 0 #FFFFFF, 4px 0 0 #FFFFFF;

    -o-box-shadow: -4px 0 0 #FFFFFF, 4px 0 0 #FFFFFF;

}



.load-more-block:after {

    background: none repeat scroll 0 0 #DDDDDD;

    content: "";

    display: block;

    height: 1px;

    margin-left: 30%;

    position: absolute;

    top: 18px;

    width: 40%;

    z-index: 1;

}



.featureImg a {

    display: block;

    overflow: hidden;

}



.featureImg a img {

    transition: all 0.4s ease 0s;

    -webkit-transition: all 0.4s ease 0s;

    -moz-transition: all 0.4s ease 0s;

    -o-transition: all 0.4s ease 0s;

    -ms-transition: all 0.4s ease 0s;

}



.featureImg a img:hover {

    transform: scale(1.1) rotate(-1.1deg);

    -webkit-transform: scale(1.1) rotate(-1.1deg);

    -moz-transform: scale(1.1) rotate(-1.1deg);

    -ms-transform: scale(1.1) rotate(-1.1deg);

    -o-transform: scale(1.1) rotate(-1.1deg);

}



/* =============================================================== */

/* PARALLAX CONTENT 1

/* =============================================================== */

.parallax-section {

    width: 100%;

    display: block;

    clear: both;

    height: auto;

}



.parallax-image-1 {

    /*background: url(../../images/parallax/parallax.jpg);*/

    background: url(../../images/slider/streetwheels_rimSlider2.jpg);

    background-size: cover;

    -webkit-background-size: cover;

    -moz-background-size: cover;

    -o-background-size: cover;

    background-size: cover;

    background-attachment: fixed; /* IE FIX */

}



.parallax-image-1.ismobile, .parallax-image-2.ismobile, .parallax-image-aboutus.ismobile, .parallax-section.ismobile {

    background-attachment: scroll !important;

}



.parallax-image-1.isios, .parallax-image-2.isios, .parallax-image-aboutus.isios, .parallax-section.isios, .blog-intro.isios {

    background-size: 100% auto !important;

    background-attachment: scroll !important;

    background-position: center center;

}



.parallax-image-1.isandroid, .parallax-image-2.isandroid, .parallax-image-aboutus.isandroid, .parallax-section.isandroid {

    background-size: cover !important;

    background-attachment: scroll !important;

    background-position: center center;

}



.parallax-image-1.isios {

    background-color: #734e48;

    background-repeat: no-repeat;

}



.parallax-image-2.isios {

    background-size: auto 100% !important;

}



.parallaxPrce {

    font-size: 64px;

    color: #fff;

    font-weight: lighter;

    margin-bottom: 10px;

}



.parallax-content h2 {

    color: #ffffff;

    display: inline-block;

    font-size: 30px;

    font-weight: 700;

    line-height: 32px;

    margin: 0 auto;

    max-width: 900px;

    padding: 5px 10px;

    text-align: center;

}



.parallax-content h3 {

    color: #DDDDDD;

    display: inline-block;

    font-size: 20px;

    font-weight: lighter;

    margin-bottom: 20px;

    max-width: 900px;

    padding: 0 10px;

    text-align: center;

}



.btn-discover {

    background: rgba(0, 0, 0, 0) none repeat scroll 0 0;

    border: 1px solid rgba(255, 255, 255, 0.7);

    border-radius: 3px;

    color: #ffffff;

    font-size: 16px;

    font-weight: 700;

    letter-spacing: 1px;

    padding: 14px 60px;

    text-decoration: none;

    transition: all .3s ease;

    -webkit-transition: all .3s ease;

    -moz-transition: all .3s ease;

    -o-transition: all .3s ease;

}



.btn-discover:hover {

    color: #fff;

    border-color: rgba(255, 255, 255, 0.7);

}



.btn-discover:hover {

}



.parallax-content {

    text-align: center;

    padding: 100px 0;

}



.brand-carousel li {

    float: left;

    display: inline-block;

}



.brand-carousel li img, .brand-carousel div img {

    max-width: 100%;

    width: 100%;

}



.carousel-nav {

    font-size: 26px;

    margin-left: 10px;

    padding-bottom: 3px;

    padding-top: 3px;

}

@media (max-width: 767px) {

    .carousel-nav {
        margin-left: 10px;
        margin-right: 10px;

    }
}

/***  -------  Parallax section 2  ------- ***/



.parallax-image-2 {

    background: url(../../images/parallax/people-collage.jpg) fixed;

    background-attachment: fixed; /* IE FIX */

}



.parallax-section-overley {

    height: 100%;

    background: rgba(0, 0, 0, 0.15);

    text-shadow: 0 1px 1px rgba(0, 0, 0, 0.33);

}



h1.xlarge {

    color: #FFFFFF;

    font-size: 44px;

    font-weight: 800;

    padding-bottom: 0;

    line-height: normal;

    text-shadow: 0 1px 3px rgba(0, 0, 0, 0.8), 0 0 1px #FFFFFF;

    text-transform: uppercase;

}



h1.x2large {

    color: #FFFFFF;

    font-size: 56px;

    line-height: normal;

    font-weight: 800;

    padding-bottom: 0;

    text-shadow: 0 1px 3px rgba(0, 0, 0, 0.8), 0 0 1px #FFFFFF;

    text-transform: uppercase;

}



h5.parallaxSubtitle {

    font-size: 20px;

    font-weight: 200;

    color: #fff;

}



/* =============================================================== */

/*  FOOTER

/* =============================================================== */



.gap {

    height: 40px;

    width: 100%;

    clear: both;

    display: block;

}



.footer {

    background: #282828;

    height: auto;

    padding-bottom: 30px;

    position: relative;

    width: 100%;

    /*
    border-bottom: 1px solid #ddd;

    border-top: 1px solid #DDDDDD;
    */
}



.footer p {

    margin: 0;

}



.footer img {

    max-width: 100%;

}



.footer h3 {

    border-bottom: 1px solid #DDDDDD;

    font-size: 18px;

    font-weight: 700;

    line-height: 27px;

    padding: 40px 0 15px;

    text-transform: uppercase;

    color: #FFF;

}



.footer ul {

    font-size: 13px;

    list-style-type: none;

    margin-left: 0;

    padding-left: 0;

    margin-top: 20px;

    color: #888888;

}



.footer ul li a {

    padding: 0 0 5px 0;

    display: block;

}



.footer a {

    color: #FFF

}



.supportLi h4 {

    font-size: 20px;

    font-weight: lighter;

    line-height: normal;

    margin-bottom: 0 !important;

    padding-bottom: 0;

}



.supportLi h4 a {

    color: #FFF;

}



.newsLatterBox input#appendedInputButton {

    background: #FFFFFF;

    display: inline-block;

    float: left;

    height: 30px;

    clear: both;

    width: 100%;

}



.newsLatterBox .btn {

    -webkit-border-radius: 3px;

    -moz-border-radius: 3px;

    -o-border-radius: 3px;

    -ms-border-radius: 3px;



    border: medium none;

    color: #fff;

    display: inline-block;

    font-weight: 700;

    height: 40px;

    letter-spacing: 0.5px;

    padding: 0;

    text-transform: uppercase;

    width: 100%;

}



.newsLatterBox {

    overflow: hidden;

}



.bg-gray {

    background-image: -moz-linear-gradient(center bottom, #BBBBBB 0%, #F0F0F0 100%);

    box-shadow: 0 1px 0 #B4B3B3;

}



.social li {

    background: none repeat scroll 0 0 #B5B5B5;

    border: 2px solid #B5B5B5;

    -webkit-border-radius: 50%;

    -moz-border-radius: 50%;

    -o-border-radius: 50%;

    -ms-border-radius: 50%;

    border-radius: 50%;

    float: left;

    height: 36px;

    line-height: 36px;

    margin: 0 8px 0 0;

    padding: 0;

    text-align: center;

    width: 36px;

    transition: all 0.5s ease 0s;

    -moz-transition: all 0.5s ease 0s;

    -webkit-transition: all 0.5s ease 0s;

    -ms-transition: all 0.5s ease 0s;

    -o-transition: all 0.5s ease 0s;

}



.social li:hover {

    transform: scale(1.15) rotate(360deg);

    -webkit-transform: scale(1.1) rotate(360deg);

    -moz-transform: scale(1.1) rotate(360deg);

    -ms-transform: scale(1.1) rotate(360deg);

    -o-transform: scale(1.1) rotate(360deg);

}



.social li a {

    color: #EDEFF1;

}



.social li:hover {

    border: 2px solid #454545;

    background: #454545;

}



.social li a i {

    font-size: 16px;

    margin: 0 0 0 8px;

    color: #EDEFF1 !important;

}



.footer-bottom {

    background: #151515;

    border-top: 1px solid #151515;

    padding-top: 10px;

    padding-bottom: 10px;

    position: relative;

    color:#eaeaea;

    height:100px;
}



.footer-bottom p.pull-left {

    padding-top: 6px;

}



.paymentMethodImg img {

    margin-left: 5px;

}



@media (max-width: 767px) {

    .footer-bottom {

        text-align: center;

    }



    .footer-bottom p.pull-left {

        float: none !important;

        margin-left: auto;

        margin-right: auto;

    }



    .paymentMethodImg {

        width: 100%;

        float: none !important;

        text-align: center;

    }



    .paymentMethodImg img {

        display: inline-block;

        float: none !important;

        max-height: 24px;

    }

}



/* =========================HOMEPAGE END==========================*/



/* =============================================================== */

/* CATEGORY PAGE START

/* =============================================================== */



.breadcrumb {

    border: 1px solid #DDDDDD;

    background: none;

}



.main-container {

    min-height: 580px;

}



.containerOffset {

    padding-top: 180px;

}

@media (max-width: 768px) {

    .containerOffset {

        padding-top: 100px;

    }
}

.headerOffset {

    padding-top: 150px;

}



.contactOffset {

    padding-top: 210px;

}



.category-top > h2 {

    color: #303030;

    font-weight: 700;

    letter-spacing: 0;

}



.subCategoryList .thumbnail {

    -moz-transition: all .2s ease-in;

    -webkit-transition: all .2s ease-in;

    -o-transition: all .2s ease-in;

    transition: all .2s ease-in;

}



.tree .dropdown-menu {

    position: relative !important;

    float: none !important;

    box-shadow: none !important;

    border-right: 0 !important;

    border-bottom: 0 !important;

    border-top: 0 !important;

}



.open-tree > .dropdown-menu {

    display: block;

}



.dropdown-menu-tree {

    display: none;

    margin-bottom: 20px;

}



.open-tree > .dropdown-menu-tree {

    display: block;

}



.tree > li.active > a, .tree > li.active > a:hover, .tree > li.active > a:focus {

    background: none;

    color: #454545;

}



.nav-stacked.tree > li a:hover {

    color: #454545;

    font-weight: bold;

}



.tree > .active > a > .badge {

    background: #999999;

    color: #fff;

}



.category-level-2 {

    border-left: 1px solid #DDDDDD !important;

    margin-left: 13px;

}



.category-level-2 li {

    margin-top: 2px;

}



.category-level-2 li a {

    font-weight: normal !important;

    padding: 2px 15px;

}



.category-level-2 li a.active, .category-level-2 .dropdown-tree.active .dropdown-tree-a, .category-level-2 .open-tree .dropdown-tree-a {

    font-weight: bold !important;

}



.priceFilterBody .form-inline .form-group {

    max-width: 30%;

    float: left;

}



.priceFilterBody .form-inline .form-group .form-control {

    width: 100%;

}



.priceFilterBody .sp {

    padding: 10px;

}



.subCategoryThumb {

    height: 80px;

    display: block;

    max-width: 100%;

    overflow: hidden;

    margin-bottom: 15px;

}



.subCategoryThumb img {

    max-width: 100%;

}



.subCategoryList {

    margin-top: 30px;

    overflow: hidden;

}



.color-details {

    border-top: 1px solid #DDDDDD;

    padding-top: 10px;

}



.productFilter {

    border-bottom: 1px solid #DDDDDD;

    border-top: 1px solid #DDDDDD;
    
    clear: both;

    display: block;

    margin-bottom: 20px;

    padding-bottom: 7px;

    
    padding-top: 10px;

    margin-top: 20px;
    
}

.productFilter9 {

    /*border-bottom: 1px solid #DDDDDD;

    border-top: 1px solid #DDDDDD;
    */
    clear: both;

    display: block;

    margin-bottom: 20px;

    padding-bottom: 7px;

    padding-top: 10px;

    margin-top: 20px;

}
.productFilter10 {

    clear: both;

    display: block;

    margin-bottom: 20px;

    padding-bottom: 7px;

}


.productFilter p {

    display: block;

    font-size: 14px;

    margin-top: 6px;

}



.change-view {

    margin-right: 10px;

}



.subCategoryTitle {

    font-size: 13px;

    color: #444;

    font-weight: 600;

}



.change-view a {

    border: 1px solid #CCCCCC;

    display: inline-block;

    font-size: 18px;

    font-weight: normal;

    height: 34px;

    line-height: 23px;

    margin-left: 5px;

    overflow: hidden;

    padding: 5px 8px;

    -webkit-border-radius: 3px;

    -moz-border-radius: 3px;

    -o-border-radius: 3px;

    -ms-border-radius: 3px;

    border-radius: 3px;

}



.productFilter select, .productFilter input {

    font-size: 13px;

}



.categoryFooter {

    border-top: solid 1px #eee;

    padding-top: 20px;

    margin-top: 0;

}



.nav-stacked > li {

    border-bottom: solid 1px #eee;

}



.nav-stacked > li a {

    font-size: 13px;

}



.nav-stacked > li a:hover {

    color: #fff;

}



.nav-stacked > li.active a, .nav-stacked > li.active {

    background: none;

    color: #454545;

    font-weight: bold;

}



/***  -------  according panel style  ------- ***/



.panel-title {

    color: inherit;

    font-size: 16px;

    margin-bottom: 0;

    margin-top: 0;

    padding-bottom: 0;

    color: #454545;

    font-size: 15px;

}



.panel-heading, .panel-group .panel {

    border-radius: 0;

}



.clearFilter {

    -webkit-border-radius: 10px;

    -moz-border-radius: 10px;

    -o-border-radius: 10px;

    -ms-border-radius: 10px;

    border-radius: 10px;

    color: #FFFFFF;

    cursor: pointer;

    font-size: 10px;

    line-height: normal;

    padding: 2px 5px;

    text-transform: capitalize;

}



.labelRounded {

    -webkit-border-radius: 10px;

    -moz-border-radius: 10px;

    -o-border-radius: 10px;

    -ms-border-radius: 10px;

    border-radius: 10px;

}



.clearFilter:hover {

    opacity: 0.7;

}



.collapseWill i {

    transition: all .3s ease;

    -webkit-transition: all .3s ease;

    -moz-transition: all .3s ease;

    -o-transition: all .3s ease;

    margin-right: 5px;



    -webkit-transform: rotate(0deg);

    -moz-transform: rotate(0deg);

    -o-transform: rotate(0deg);

    -ms-transform: rotate(0deg);

    transform: rotate(0deg);

}



.collapseWill.pressed i, .collapseWill.active i {

    margin-right: 5px;

    -webkit-transform: rotate(90deg);

    -moz-transform: rotate(90deg);

    -o-transform: rotate(90deg);

    -ms-transform: rotate(90deg);

    transform: rotate(90deg);

}



.minus {

    display: block;

}



.collapseWill .minus {

    display: none !important;

}



.collapseWill.collapsed .minus {

    display: none !important;

}



.collapseWill {

}



.color-filter small {

    border: 1px solid #DDDDDD;

    display: inline-block;

    height: 14px;

    margin: 0 3px 0 1px;

    width: 14px;

}



/* =============================================================== */

/* PRODUCT DETAILS PAGE

/* =============================================================== */



.categoryFilter {

    padding-bottom: 20px;

    border-bottom: solid 1px #ddd;

    margin-bottom: 20px;

    display: block;

    width: 100%;

    height: 50px;

}



.categoryFilter .minict_wrapper {

    max-width: 180px;

    max-height: 28px;

    height: 28px !important;

}



.categoryFilter .minict_wrapper input {

    height: 28px !important;

    font-size: 12px !important;

}



.minict_wrapper input {

    border: 0 !important;

}



.categoryFilter .minict_wrapper ul li {

    font-size: 12px !important;

}



.nav-tabs > li.active, .nav-tabs > li {

    background: none;

}



.nav-tabs > li > a {

    border: 0;

    border-radius: 0;

}



.nav-tabs > li.active > a, .nav-tabs > li.active > a:hover, .nav-tabs > li.active > a:focus, .nav-tabs > li:hover > a {

    color: #fff !important;

    border: 0;

    border-radius: 0;

}



/* -------- zoom ------- */

.sp-wrap {

    border: none !important;

}



.sp-thumbs {

    text-align: center !important;

}



.sp-large a img {

    width: 100%;

}



.sp-wrap {

    z-index: 10;

    width: 100%;

    background: none !important;

}



.sp-large {

    max-width: 90%;

}



.sp-thumbs {

    position: absolute;

    right: 0 !important;

    text-align: left;

    bottom: 140px !important;

    width: 100px !important;

    z-index: 70;

}



.sp-thumbs a:link, .sp-thumbs a:visited {

    border: 2px solid #EEEEEE;

    display: inline-block;

    margin-bottom: 10px;

    float: right;

    transition: margin 0.2s ease 0s;

}



.main-image .sp-thumbs a:link, .sp-thumbs a:visited {

    margin-right: 0 !important;

}



.sp-thumbs a:hover {

    margin-right: 4px !important;

}



.sp-thumbs img {

    height: auto !important;

    width: 60px !important;

}



/***  -------  Product details style 2   ------- ***/



.style2 .sp-thumbs {

    top: 0 !important;

    width: 19% !important;

}



.style2 .sp-thumbs img {

    height: auto !important;

    width: 100px !important;

    max-width: 100%;

    width: 100px !important;

}



.style2 .sp-thumbs a:link, .style2 .sp-thumbs a:visited {

    float: left !important;

    width: 100%;

}



.style2 .sp-large {

    max-width: 80% !important;

    border: 1px solid #DDDDDD;

}



.product-title {

    font-size: 30px;

    font-weight: 600;

    letter-spacing: 0.25px;

    line-height: 1;

    margin-bottom: 0;

    text-transform: uppercase;

}



.product-price {

    font-size: 28px;

    font-weight: 600;

    line-height: normal;

    margin-bottom: 10px;

}



.price-standard {

    color: #999999;

    font-size: 18px;

    text-decoration: line-through;

}



.product-code {

    font-family: oswald, Helvetica Neue, Arial, sans-serif;

    font-size: 14px;

    font-weight: lighter;

    letter-spacing: 1px;

    margin: 0 0 5px;

    padding: 0;

    text-transform: uppercase;

}



.rating span {

    display: inline-block;

}



.ratingInfo {

    margin-left: 10px;

}



/*------- product-details style 4 ------- */



.zoomThumb {

    text-align: center;

    margin-top: 10px;

}



.zoomThumb a {

    display: inline-block;

    width: 120px;

    margin-bottom: 10px;

    border: 2px solid #eee;

    display: inline-block;

    margin-right: 5px;

}



.zoomThumb a img {

    max-width: 100%;

}



.zoomImg {

    cursor: crosshair;

}



/*------- product tab ------- */



.nav-tabs {

    margin-bottom: 20px;

}



.product-tab {

    width: 100%;

    display: inline-block;

    clear: both;

}



@media (max-width: 768px) {

  .nav-justified > li {

    display: table-cell;

    width: 1%;

  }

  .nav-justified > li > a  {

    border-bottom: 1px solid #ddd !important;

    border-radius: 4px 4px 0 0 !important;

    margin-bottom: 0 !important;

  }

}



.nav-tabs li a {

    font-family: 'Oswald', sans-serif;

    font-size: 14px;

    /*color: #    ;*/

    font-weight: 320;

    letter-spacing: 2px;

    margin: 0 0 0 0;

    padding: 15px;

    text-transform: uppercase;

}



.color-details {

    margin-top: 14px;

}



.swatches {

    margin: 0.5em 0;

    padding-left: 0;

}



.swatches li {

    border: 2px solid #FFFFFF;

    display: inline-block;

    margin: 2px 1px;

    vertical-align: top;

    list-style: none;

}



.swatches .selected, .swatches li:hover {

    border: 2px solid #ddd;

}



.swatches a {

    position: relative;

    background-position: center center;

    background-repeat: no-repeat;

    border: 2px solid #FFFFFF;

    box-shadow: 1px 1px 2px #C5C5C5;

    display: block;

    height: 33px;

    text-align: center;

    transition: all 0.25s ease 0s;

    width: 33px;

}



.swatches a img {

    height: 29px;

    width: 29px;

}



.swatches li a:after {

    content: "\f00c";

    font-family: fontawesome;

    font-size: 0;

    font-weight: lighter;

    color: #fff;

    transition: font-size 0.25s ease 0.1s;

    text-shadow: 0 1px 1px rgba(0, 0, 0, 0.3);



}



.swatches .selected a:after {

    content: "\f00c";

    font-family: fontawesome;

    font-size: 100%;

    font-weight: lighter;

    color: #fff;

    transition: font-size 0.25s ease 0.1s;

    text-shadow: 0 1px 1px rgba(0, 0, 0, 0.3);



}



/***  ------- filterBox ------- ***/



.filterBox {

    display: inline-block;



}



.productFilterLook2 .filterBox {

    display: block;

    float: left;

    margin-right: 0;

    width: 100%;

}



.cart-actions {

    margin-top: 0;

    margin-bottom: 20px;

    width: 100%;

    display: inline-block;

    border-bottom: 1px solid #DDDDDD;

    padding-bottom: 12px;

    clear: both;

}



.cart-actions button, .cart-actions .btn-cart, .cart-actions .wishlist {

    -webkit-border-radius: 3px;

    -moz-border-radius: 3px;

    -o-border-radius: 3px;

    -ms-border-radius: 3px;

    border-radius: 3px;

    color: #FFFFFF;

    display: block;

    float: left;

    font-family: Oswald, Helvetica Neue, Arial, sans-serif;

    font-size: 18px;

    font-weight: lighter;

    line-height: 1em;

    margin: 0 20px 0 0;

    padding: 14px 20px;

    text-transform: uppercase;

    transition: all 0.25s ease 0s;

    -moz-transition: all 0.25s ease 0s;

    -webkit-transition: all 0.25s ease 0s;

    -o-transition: all 0.25s ease 0s;

    -ms-transition: all 0.25s ease 0s;

}



button.first {

    margin-right: 20px;

    margin-bottom: 10px;

}



.cart-actions .wishlist {

    background: #CCCCCC;

    line-height: 1em;

    text-decoration: none;

    border: solid 2px #CCCCCC;

    text-align: center;

}



.cart-actions .wishlist:hover {

    background: #fff;

    color: #666;

    border: solid 2px #CCCCCC;

}



.incaps {

    display: block;

    float: left;

    font-family: 'Oswald', sans-serif;

    font-size: 14px;

    font-weight: normal;

    letter-spacing: 2px;

    margin: 15px 20px 0 0;

    text-transform: uppercase;

}



.incaps i {

    font-size: 20px;

}



.color-in {

    color: #4CC94A;

}



.color-out {

    color: red;

}



.product-share {

    border-top: 1px solid #DDDDDD;

    clear: both;

    display: inline-block;

    margin-top: 20px;

    padding-top: 14px;

    width: 100%;

}



.socialIcon a {

    background: #CCCCCC;

    color: #FFFFFF;

    display: inline-block;

    font-size: 22px;

    padding: 6px 3px;

    margin-right: 10px;

    text-align: center;

    width: 35px;

    transition: all 0.15s ease-in-out 0s;

    -moz-transition: all 0.15s ease-in-out 0s;

    -webkit-transition: all 0.15s ease-in-out 0s;

    -o-transition: all 0.15s ease-in-out 0s;

    -ms-transition: all 0.15s ease-in-out 0s;

}



.socialIcon a:hover {

    color: #fff;

}



/***  ------- You may also like ------- ***/



.recommended {

    border-top: solid 2px #ddd;

    margin-top: 20px;

    padding-top: 20px;

}



.recommended h1 {

    text-align: center;

    margin-bottom: 0;

    padding-bottom: 0;

}



#SimilarProductSlider .item {

    padding: 30px 0px 0;

    margin: 10px;

    color: #FFF;

    -webkit-border-radius: 3px;

    -moz-border-radius: 3px;

    border-radius: 3px;

    text-align: center;

}



.recommended .description {

    min-height: 70px;

}



.product-image {

    max-width: 100%;

}



.product-image img {

    max-width: 100%;

}



#SimilarProductSlider .product-image img {

    max-height: 200px;

}



/* =============================================================== */

/* CART PAGE

/* =============================================================== */


.cartColumn4 {

    width: 93px !important;

}


.quanitySniper {

    height: 30px;

    padding: 0;

    text-align: center;

    width: 40px;

}
input.quanitySniper {

    width: 35px !important;

}



tr.CartProduct td, tr.CartProduct th {

    text-align: center;

}



tr.CartProduct td:nth-child(2) {

    text-align: left;

}



.cartTable {

    border-top: solid 1px #EBEDEF;

}



.cartTableHeader {

    background: #EBEDEF;

}



.cartFooter {

    padding: 20px;

    border-top: solid 1px #eee;

    background: #EBEDEF;

    display: inline-block;

    width: 100%;

}



#cart-summary tr td:nth-child(2) {

    text-align: right;

}



/* =============================================================== */

/* Right side bar  //> .rightSidebar //> if needed

/* =============================================================== */



.caps {

    border-top: 0 none;

    font-weight: normal;

    line-height: 24px;

    margin: 0 0 16px;

    overflow: hidden;

    padding: 0 0 16px;

    text-align: right;

    text-transform: uppercase;

}



.rightSidebar #cart-summary {

    margin-top: 0;

}



.rightSidebar #cart-summary tr:first-child td {

    border: none;

}



.cartMiniTable {

    border: 1px solid #DDDDDD;

    padding: 10px;

}



.rightSidebar img {

    max-width: 100%;

}



.rightSidebar .section-title-inner {

}



.categoryListPanel {

    background: #EBEDEF;

}



.contentBox {

    margin-bottom: 15px;

}



.rightSidebar .product-image img {

    float: left;

    max-width: 100%;

    width: 90px;

    margin-right: 10px;

}



.rightSidebar .product {

    padding: 10px 0;

}



.rightSidebar .item {

    border-bottom: 1px solid #DDDDDD;

    margin-bottom: 0;

}



.rightSidebar .item h4 {

    margin-top: 0;

}



.couponForm input[type="text"] {

    -webkit-border-radius: 3px 0 0 3px;

    -moz-border-radius: 3px 0 0 3px;

    border-radius: 3px 0 0 3px;

    height: 35px;

}



.couponForm .btn {

    border-radius: 0 3px 3px 0;

}



span.success, p.success div.success {

    color: #8BB418;

}



#total-price {

    font-size: 22px;

    font-weight: bold;

}



/* =============================================================== */

/*  CHECKOUT PAGE START

/* =============================================================== */



label.error {

    color: #e74c3c;

    font-size: 90%;

}



.orderStep {

    clear: both;

    display: inline-block;

    height: auto;

    padding: 0;

    margin-bottom: 30px;

    width: 100%;

}



.orderStep li {

    display: inline-block;

    float: left;

    height: auto;

    margin: 0;

    min-height: 55px;

    padding: 0;

    width: 25%;

}



.orderStep li.active a {

    color: #fff;

    background-color: #173555;

}



.orderStep li.active a {

    position: relative;

}



.orderStep li.active a:after {

    top: 100%;

    left: 50%;

    border: solid transparent;

    content: " ";

    height: 0;

    width: 0;

    position: absolute;

    pointer-events: none;

    border-color: rgba(136, 183, 213, 0);

    border-width: 10px;

    margin-left: -10px;

}



.orderStep li a {

    background: #fff;

    display: inline-block;

    font-size: 14px;

    height: 100%;

    line-height: normal;

    padding: 20px 0 0;

    text-align: center;

    vertical-align: middle;

    width: 100%;

    text-transform: uppercase;

    font-size: 13px;

}



.orderStep li a i {

    /*background: rgba(0, 0, 0, 0.05);*/

    background: rgba(172, 217, 249, 0.55);

    /*background: rgba(219, 125, 48, 0.3);*/

}



.orderStep li.active a i {

    background: rgba(0, 0, 0, 0.1);

    color: #FFFFFF;

}



.orderStep li a i {

    border-radius: 0;

    display: block;

    font-size: 20px;

    height: auto;

    left: 0;

    line-height: 40px;

    margin-top: -20px;

    padding: 0;

    text-align: center;

    width: auto;

    float: none !important;

}



.orderStep li a span {

    display: block;

    padding: 5px 0;

}



.orderStep li .disable {

    background: #fff;

    display: inline-block;

    font-size: 14px;

    height: 100%;

    line-height: normal;

    padding: 20px 0 0;

    text-align: center;

    vertical-align: middle;

    width: 100%;

    text-transform: uppercase;

    font-size: 13px;

}



.orderStep li .disable i {

    /*background: rgba(0, 0, 0, 0.05);*/

    background: rgba(172, 217, 249, 0.55);

}



.orderStep li .disable i {

    border-radius: 0;

    display: block;

    font-size: 20px;

    height: auto;

    left: 0;

    line-height: 40px;

    margin-top: -20px;

    padding: 0;

    text-align: center;

    width: auto;

    float: none !important;

}



.orderStep li .disable span {

    display: block;

    padding: 5px 0;

}



/* payment */



.paymentBox {

    margin-bottom: 30px;

    display: block;

}



.checkoutReview .CartProductThumb a img, .checkoutReview .CartProductThumb a img {

    width: 50px;

}



.paymentBox .panel {

    box-shadow: none;

}



/* =============================================================== */

/* USER END || Account// Address // Order //  Creation

/* =============================================================== */



.myAccountList li {

    text-align: center;

}



.myAccountList li a {

    font-size: 14px;

    display: block;

}



.myAccountList .thumbnail {

    padding: 10px 0 0;

}



.myAccountList .thumbnail:hover {

    background: #F5F5F5;

}



.myAccountList li a i {

    display: block;

    clear: both;

    font-size: 44px;

    margin-bottom: 10px;

}



h2.block-title-2, .formBox h3, .block-title-2 {

    border-bottom: 1px solid #CECECE;

    font-size: 13px;

    font-weight: bold;

    margin: 0 0 15px;

    padding: 10px 0 0;

    position: relative;

    text-transform: uppercase;

}



.panel-footer-address .btn {

    margin-right: 5px;

}



/***  ------- Cart & Wisth list ------- ***/



.CartProductThumb a img, .CartProductThumb a img {

    width: 86px;

    max-width: 100%;

}



tr.CartProduct {

    border-bottom: 1px solid #E7E9EC;

}



tr.CartProduct td, tr.CartProduct th {

    padding: 10px 0;

}



/* mycart */



.cartTableHeader {

    text-transform: uppercase;

    font-weight: bold;

    font-size: 14px;

}



/* =============================================================== */

/* ABOUT PAGE

/* =============================================================== */



.innerPage {

    padding-top: 0px;

}

.parallaxOffset {

    margin-top: 80px;

    -webkit-transition: all 0.1s ease-out 0s;

    -moz-transition: all 0.1s ease-out 0s;

    -ms-transition: all 0.1s ease-out 0s;

    -o-transition: all 0.1s ease-out 0s;

    transition: all 0.1s ease-out 0s;

}

.parallaxOffset.down {

    margin-top: 28px;

}



.parallax-image-aboutus {

    background: url(../../images/site/about-bg.jpg) no-repeat 50% -80px fixed;

    -webkit-background-size: cover;

    -moz-background-size: cover;

    -o-background-size: cover;

    background-size: cover;

    background-attachment: fixed; /* IE FIX */

}



.parallax-image-aboutus .parallax-content {

    padding: 150px 0;

}



.title-big {

    font-size: 48px;

    font-weight: bold;

    margin-bottom: 15px;

}



.title-medium-big {

    font-size: 40px;

    font-weight: 500;

    margin-bottom: 15px;

}



.title-medium {

    font-size: 30px;

    font-weight: 500;

    margin-bottom: 15px;

}



.title-medium-italic {

    font-size: 40px;

    font-weight: 400;

    margin-bottom: 15px;

    font-style: italic;

}



.padding-top-30 {

    padding-top: 30px;

}



.form-newsletter input {

    margin-bottom: 20px;

}



.hr30 {

    margin-top: 30px;

    margin-bottom: 30px;

}



.hr40 {

    margin-top: 40px;

    margin-bottom: 40px;

}



/* =============================================================== */

/* CONTACT US PAGE

/* =============================================================== */



.whitebg {

    background: #fff;

}



.fixedContent {

    position: fixed;

    top: 0;

    width: 100%;

    display: block;

}



.wrapper.contact-us {

    background: #FFFFFF;

    display: block;

    margin-top: 450px;

    position: relative;

    width: 100%;

    z-index: 4;

}

.contact-us
{
    margin-top:20px
}

@media (max-width: 768px) {

    .contact-us
    {
        margin-top:80px
    }

}




.h1error {

    font-size: 80px;

    font-weight: bold;

    margin: 0 auto;

    padding: 20px;

    text-align: center;

}



.err404 {

    font-weight: lighter;

}



/* =============================================================== */

/* PRODUCT DETAILS POPUP MODAL

/* =============================================================== */



/* quick view btn */

div.quickview {

    display: block;

    margin-top: 40%;

    max-height: 50px;

    position: absolute;

    text-align: center;

    width: 100%;

    z-index: 2;

    visibility: hidden;

}



.product:hover div.quickview {

    visibility: visible;
   
}



.btn-quickview {

    border-radius: 2px;

    font-family: "Oswald", Helvetica Neue, Arial, sans-serif;

    letter-spacing: 2px;

    color: #454545 !important;

    text-transform: uppercase;

    background: #fff; /* old borwser */

    background: rgba(255, 255, 255, 0.9);

    border: 1px solid #eee;

    box-shadow: 0 0 3px rgba(0, 0, 0, 0.3);

    -webkit-box-shadow: 0 0 3px rgba(0, 0, 0, 0.3);

    -ms-box-shadow: 0 0 3px rgba(0, 0, 0, 0.3);

    -moz-box-shadow: 0 0 3px rgba(0, 0, 0, 0.3);

    -moz-o-shadow: 0 0 3px rgba(0, 0, 0, 0.3);

    left: auto;

    min-width: 100px;

    max-height: 40px;

    height: 40px !important;

    opacity: 0;

    -webkit-opacity: 0;

    -ms-opacity: 0;

    padding: 10px;

    visibility: hidden;

    width: auto;

    display: inline-block !important;

    z-index: 2;

    transform: scale(0.75);

    -webkit-transform: scale(0.75);

    -o-transform: scale(0.75);

    -ms-transform: scale(0.75);

    -moz-transform: scale(0.75);

    transition: all 0.3s ease 0s;

    -webkit-transition: all 0.3s ease 0s;

    -webkit-transition: all 0.3s ease 0s;

    -moz-transition: all 0.3s ease 0s;

    -ms-transition: all 0.3s ease 0s;

}



.product:hover .btn-quickview {

    visibility: visible;

    opacity: 0.85;

    -webkit-opacity: 0.85;

    -ms-opacity: 0.85;

    transform: scale(1);

    -webkit-transform: scale(1);

    -o-transform: scale(1);

    -ms-transform: scale(1);

    -moz-transform: scale(1);

}



.list-view .product:hover div.quickview {

    margin-top: 45%;

}



@media (max-width: 460px) {

    div.quickview {

        margin-top: 25%;

    }

}



@media (max-width: 400px) {

    div.quickview {

        margin-top: 35%;

    }

}



.product > .btn-quickview:hover {

    opacity: 0.95;

}



/* quick view btn end */



#product-details-modal .modal-dialog {

    background: #fff;

    max-width: 960px;

    overflow: auto;

    width: 100%;

}



#product-details-modal .close, #productSetailsModalAjax .close {

    color: #454545;

    display: block;

    font-size: 34px;

    font-weight: lighter;

    line-height: 10px;

    opacity: 0.6;

    padding: 12px;

    position: absolute;

    right: 0;

    text-shadow: none;

    top: 0;

    z-index: 1;

}



#productSetailsModalAjax .main-image {

    margin-top: 40px;



}



#product-details-modal .close:hover, #productSetailsModalAjax .close:hover {

    opacity: 0.8;

}



@media (max-width: 800px) {

    #product-details-modal .modal-dialog, #productSetailsModalAjax .modal-dialog {

        max-width: 90%;

        margin: 20px auto;

    }

}



@media (min-width: 840px) {

    .modal-details-inner {

        min-height: 552px;

    }

}



@media (max-width: 600px) {

    #product-details-modal .modal-dialog {

        max-width: 96%;

        margin: 20px auto;

    }

}



#product-details-modal .modal-content {

    background: #fff;

    display: block;

    height: auto;

    padding: 0;

    box-shadow: none;

}



.modal-details-inner {

    padding: 30px;

}



#product-details-modal .productFilter {

    margin-bottom: 10px;

    margin-top: 10px;

    padding-bottom: 10px;

    padding-top: 10px;

}



#product-details-modal .filterBox {

    display: inline-block;



}



#product-details-modal .cart-actions {

    margin-bottom: 0;

    margin-top: 0;

}



#product-details-modal .color-details {

    margin-top: 5px;

}



#product-details-modal .product-share {

    border-top: 0 none;

    margin-top: 0;

    padding-top: 0;

}



.modal-product-thumb {

    border-top: 1px solid #ddd;

    clear: both;

    display: block;

    text-align: center;

}



.modal-product-thumb a {

    border: 1px solid #ccc;

    display: inline-block;

    float: none;

    margin-right: 10px !important;

    margin-top: 5px;

    height: 75px;

    overflow: hidden;

    width: 60px !important;

}



.modal-product-thumb a.selected, .modal-product-thumb a:hover {

    border: 1px solid #000 !important;

}



.modal-product-thumb a img {

    display: block;

    max-width: 100%;

    height: auto;

    max-height: 75px;

    width: 60px !important;

}



/* =============================================================== */

/* SUBSCRIBE | PROMOTIONAL MODAL

/* =============================================================== */



#modalAds .modal-bg-1 {

    background: url(../../images/site/modal-img-1.jpg) right bottom no-repeat;

    background-color: #F3F6FB;

}



.modal-bg-1 .close {

    color: #454545;

    display: block;

    font-size: 34px;

    font-weight: lighter;

    line-height: 10px;

    opacity: 0.6;

    padding: 12px;

    position: absolute;

    right: 0;

    text-shadow: none;

    top: 0;

    z-index: 1;

}



#modalAds .modal-dialog {

    max-width: 660px;

    min-height: 340px;

    display: block;

    overflow: auto;

    padding: 15px;

    position: relative;

}



#modalAds h3 {

    color: #444;

    font-family: Oswald, Helvetica Neue, Helvetica, Arial, sans-serif;

    font-size: 26px;

    font-weight: normal;

    letter-spacing: 1px;

    line-height: 29px;

    text-transform: uppercase;

}



.discountLg {

    color: #000;

    font-family: Oswald, Helvetica Neue, Helvetica, Arial, sans-serif;

    font-size: 46px;

    font-weight: bold;

    letter-spacing: 3px;

    line-height: 46px;

    text-transform: uppercase;

}



#modalAds h4 {

    font-size: 24px;

    font-weight: normal;

    letter-spacing: 2px;

    line-height: 28px;

    text-transform: uppercase;

}



.newsletter {

    border: 2px solid #ddd;

    display: inline-block;

    width: auto;

}



.newsletter input[type="text"] {

    background: #fff;

    border: medium none;

    float: left;

    height: 40px;

    margin: 0;

    padding: 0;

    text-align: center;

}



.newsletter input[type="text"]:focus {

    text-shadow: none;

    color: #666;

}



.newsletter .subscribe-btn {

    background: #ddd;

    border: 0 none;

    border-radius: 0;

    color: #3a3a3a;

    float: left;

    font-weight: normal;

    height: 40px;

    line-height: normal;

    padding: 0 10px;

}



@media (max-width: 442px) {

    .discountLg {

        font-size: 36px;

        letter-spacing: 2px;

        line-height: 40px;

    }



    #modalAds h3 {

        color: #444;

        font-size: 22px;

        letter-spacing: 1px;

        line-height: 26px;

    }



    .newsletter input[type="text"] {

        width: 100%;

    }



    .newsletter .subscribe-btn {

        margin-left: 0;

        margin-top: 5px;

        width: 100%;

    }

}



.newsletter .subscribe-btn:hover {

    background: #b7b7b7;

}



.shoplink {

    color: #000;

    display: inline-block;

    clear: both;

    font-family: Oswald, Helvetica Neue, Helvetica, Arial, sans-serif;

    left: 2px;

    margin-top: 20px;

    text-decoration: underline;

    text-transform: uppercase;

}



/* =============================================================== */

/* WEB PAGE PROGRESS BAR CSS

/* =============================================================== */



.pace .pace-progress {

    background: #fff;

    background: rgba(255, 255, 255, 0.80);

    position: fixed;

    z-index: 2000;

    top: 0;

    left: 0;

    height: 2px;

    -webkit-transition: width 1s;

    -moz-transition: width 1s;

    -o-transition: width 1s;

    transition: width 1s;

}



.pace-inactive {

    display: none;

}



/* =============================================================== */

/* CUSTOM RESPONSIVE

/* =============================================================== */



@media (min-width: 1800px) {

    .banner, .slider-content, .slider, .slider-v2 {

        max-height: 700px;

    }



    .sliderImg {

        width: 100%;

    }

}



@media only screen and (max-width: 1224px) {

    input.quanitySniper {

        width: 35px !important;

    }

}



@media (max-width: 1224px) {

    .navbar.stuck .navbar-top {

        margin-top: 0 !important;

    }



    .banner {

        margin-top: 70px !important;

    }



    .header-version-2 .banner {

        margin-top: 130px !important;

    }



    .social li {

        margin: 0 6px 0 0;

    }



    .cartMenu.static-search-box a.dropdown-toggle {

        padding-left: 10px;

        padding-right: 10px;

    }



    .search-box.static-search .form-control {

        max-width: 104px

    }

}



@media (max-width: 1279px) and (min-width: 768px) {

    .cartMenu a.dropdown-toggle {

        height: 60px;

    }



    .cartRespons {

        display: none;

    }

}



/* // NEW  */

@media (max-width: 991px) and (min-width: 768px) {



    .navbar-nav > li > a {

        padding-left: 10px;

        padding-right: 10px;

        font-size: 12px;

    }



    .cartMenu a.dropdown-toggle {

        font-size: 12px;

        padding: 15px 15px;

    }



}

.mobile-logo    
{
    display:none;
}

.mobile-search    
{
    display:none;
}

#top-banner
{
    background-color:#FFF;
    width:100%;
    z-index:1100;
    height: 90px;
    position:fixed;
    top:0px;  
    display:initial;
}

@media (max-width: 767px) {

    .navbar-nav 
    {

        width:100% !important;
        margin-left:0px !important;
        
    }

    .navbar-collapse
    {
    }

    .mobile-logo    
    {
        margin-left:15px;
        margin-top:7px;
        float:left;
        display:block;
    }

    .mobile-search    
    {
        display:block;
        margin-bottom:15px;
        margin-top:10px;
    }


    #top-banner
    {
        display:none;
    }
}



@media (min-width: 767px) {

    .mobile-cart
    {
        display:none !important;
    }



}

@media (max-width: 979px) {



    /*** iPads (portrait and landscape) ***/

    .subCategoryThumb img {

        height: 100% !important;

    }



    .container {

        width: 100% !important;

        max-width: 900px !important;

    }



    .category-top {

        margin-top: 30px;

    }



    .cartRespons {

        font-size: 0;

    }



    .hidden-xs .cartMenu .dropdown-toggle {

        padding-bottom: 9px;

        padding-top: 19px;

    }



    .sliderText {

        margin-top: 6%;

    }



    .header-version-2 .sliderText {

        margin-top: 10%;

    }



    .sliderText .inner {

        padding: 15px;

    }



    .sliderText h1 {

        font-size: 30px;

    }



    .sliderText.white h1 {

        padding-bottom: 0;

    }



    .sliderText.white h3.price {

        font-size: 18px;

        padding-bottom: 0;

    }



    .sliderText.white .inner {

        padding: 5px 0;

    }



    h1.xlarge {

        font-size: 36px;

    }



    .sliderTextFull h3 {

        font-size: 18px;

        line-height: 20px;

        padding-bottom: 5px;

    }



    .sliderInfo .btn-lg, .sliderText.white .btn {

        font-size: 14px;

        line-height: 1.471;

        padding: 5px 15px;

    }



    #pager2 {

        bottom: 0;

    }



    #pager2 span {

        border-width: 1px;

        height: 10px;

        width: 10px;

    }



    .sliderControl {

        border-radius: 2px;

        padding: 0 2px;

        top: 45%;

    }



    .sliderControl .fa-3x {

        font-size: 2.5em;

    }



    #pager a {

        font-size: 14px;

        padding: 5px 0;

        font-weight: normal;

    }

}



@media (min-width: 768px) {



    .megamenu-content.has20 {

        width: 202px;

    }



    .megamenu-content.has40 {

        width: 460px;

    }



    .megamenu-content.has60 {

        width: 670px;

    }



    .megamenu-content.has4col {

        width: 700px;

    }



    .hasMega {

        position: relative !important;

    }



    .hasMega .dropdown-menu {

        min-width: 202px;

    }



    .hasMega.megamenu-20width .dropdown-menu {

        min-width: 202px;

    }



    .hasMega.megamenu-40width .dropdown-menu {

        min-width: 460px;

    }



    .hasMega.megamenu-60width .dropdown-menu {

        min-width: 670px;

    }



    .hasMega.megamenu-70width .dropdown-menu {

        min-width: 700px;

    }



}



@media (max-width: 767px) and (min-width: 0) {

    /***  ipad 4 , iphone 4 fixed  ***/

    .hidden-xs {

        display: none !important;

        visibility: hidden !important;

    }



}



@media screen and (max-width: 767px) {

    /* phones */

    .static-search.navbar-formbox {

        margin: 0;

        padding: 0;

        max-width: 100%;

    }



    .static-search.navbar-formbox {

        margin: 0;

        max-width: 100%;

        padding: 0;

        width: 100%;

    }



    .static-search.navbar-formbox .navbar-form {

        margin: 0;

        padding: 0;

        border: 0;

        box-shadow: none;

    }



    .search-box.static-search .input-group {

        max-width: 100%;

        width: 100%;

    }



    .search-box.static-search .form-control {

        max-width: 100%;

        width: 100%;

    }



    .search-box.static-search:hover .form-control {

        max-width: 100%;

        width: 100%;

    }



    .userMenu li a {

        padding-top: 3px;

        line-height: normal;

    }



    .phone-number i.glyphicon-phone-alt {

        padding-top: 2px !important;

    }



    .navbar-brand {

        padding: 15px;

    }



    .search-box .input-group {

        margin-top: 9px;

    }



    .search-box .input-group .btn {

        box-shadow: none;

        padding: 9px 10px;

    }



    .miniCartFooter {

        background: none repeat scroll 0 0 rgba(0, 0, 0, 0) !important;

        border-top: 2px solid rgba(255, 255, 255, 0.5) !important;

        margin-top: 10px !important;

    }



    .cartMenu table tr {

        border-bottom: solid 1px rgba(255, 255, 255, 0.1);

    }



    .cartRespons {

        font-size: 14px;

    }



    .navbar-cart {

        padding-right: 0;

        padding-left: 0;

    }



    .navbar-cart.in .mCS-dark-2 > .mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {

        background: #fff;

        background: rgba(255, 255, 255, 0.7) !important;

    }



    .miniCartFooter .btn {

        color: #fff !important;

    }



    .miniCartProduct, .miniCartProduct a, .miniCartProduct p, .miniCartProduct .size, .miniCartProduct .price, .dropdown-header {

        color: #FFFFFF;

    }



    .megamenu-content ul li a, .megamenu-content ul li p, .megamenu-content ul li, .navbar-nav .dropdown a, .navbar-nav .dropdown {

        color: #fff;

    }



    .searchInputBox {

        max-width: 80%;

    }



    .megamenu .dropdown.megamenu-80width .dropdown-menu {

        width: 100%;

        left: 0;

        right: 0;

    }



    .megamenu .dropdown.megamenu-80width .dropdown-menu {

        width: 100%;

        left: 0;

        right: 0;

    }



    .megamenu .dropdown.megamenu-50width .dropdown-menu {

        width: 100%;

        left: 0;

        right: 0;

    }



    .megamenu .dropdown.megamenu-40width .dropdown-menu {

        width: 100%;

        left: 0;

        right: 0;

    }



    .megamenu-content ul {

        margin-bottom: 15px;

    }



    .megamenu-content ul li {

        border-color: rgba(255, 255, 255, 0.2);

    }



    /***  banner slider responsive  ***/

    .banner {

        margin-top: 70px !important;

    }



    .sliderTextFull .inner.dark {

        max-width: 100%;

        padding: 5px;

    }



    .parallaximg.ismobile {

        margin-top: 0 !important;

    }



    h1.xlarge {

        font-size: 20px;

        font-weight: 600;

        line-height: normal;

        padding-bottom: 0;

    }



    .sliderTextFull h3 {

        font-size: 16px;

        line-height: normal;

        margin-bottom: 5px;

        margin-top: 0;

        padding-bottom: 0;

    }



    .sliderText h1 {

        font-size: 24px;

        padding-bottom: 0;

    }



    .sliderInfo .btn-lg {

        font-size: 12px;

        padding: 5px 10px;

    }



    .sliderText .inner {

        padding: 5px;

    }



    .sliderText h3.price {

        font-size: 16px;

        padding-bottom: 2px;

    }



    .featureImg a {

        margin-bottom: 20px;

    }



    .navbar-top i {

        font-size: 16px;

        font-style: normal;

        font-weight: normal;

        line-height: 1;

        padding: 3px 0 0;

    }



    .text-left-xs {

        text-align: left !important;

    }



    /* Home v5 Slider */

    .ps-nav {

        top: 34%;

    }



    .ps-nav img {

        width: 80px;

    }



    .cart-actions button, .cart-actions .btn-cart, .cart-actions .wishlist {

        margin-bottom: 15px;



    }



}



@media (min-width: 0) and (max-width: 991px) {

    .cart-actions button, .cart-actions .btn-cart, .cart-actions .wishlist {

        margin-bottom: 15px;



    }

}



@media screen and (max-width: 580px) {

    /* phones (portrait and landscape) ----------- */

    .xs3Response {

        width: 50% !important;

    }



    .sliderTextFull {

        color: #FFFFFF;



    }



    .parallax-content h2 {

        color: #FFFFFF;

        display: inline-block;

        font-size: 20px;

        line-height: 22px;

    }



    .parallax-content h3 {

        font-size: 16px;

        line-height: normal;

    }



    .style2 .section-title, .section-title.style2 {

        font-size: 24px;

    }



    .style2 .section-title:after, .section-title-style2:after, .section-title.style2:after, .section-title-style2:after {

        margin-left: 10%;

        top: 32px;

        width: 80%;

    }



    .orderStep li a span {

        font-size: 11px;

    }



    .categoryFooter .pagination {

        margin-bottom: 10px;

    }



    .sp-thumbs {

        bottom: 0 !important;

    }



    .CartProductThumb a img {

        margin-right: 5px;

    }



    #pager2 {

        bottom: 0;

    }



    #pager2 span {

        border-width: 1px;

        height: 10px;

        width: 10px;

    }



    .sliderControl {

        border-radius: 2px;

        padding: 0 2px;

        top: 45%;

    }



    .sliderControl .fa-3x {

        font-size: 2em;

    }



    #pager a {

        font-size: 14px;

        padding: 5px 0;

        font-weight: normal;

    }



    /* Home v5 Slider */

    .ps-nav {

        top: 28%;

    }



    .ps-nav img {

        width: 80px;

    }



    #imageShowCase .product-slide h1 {

        font-size: 18px;

        letter-spacing: 1px;

        line-height: 22px;

    }



    .newsletter {

      border: 0;

    }

    #subscribe {

        border: solid 1px #ddd;

    }





    .modal-dialog {

        background: #fff none repeat scroll 0 0;

        margin: 6% auto;

        max-width: 95%;

        overflow: auto;

        width: 100%;

    }



    #modalAds .modal-dialog {

        display: block;

        margin: 10% auto;

        max-width: 94%;

        min-height: 340px;

        overflow: auto;

        padding: 10px 0;

        position: relative;

    }







}



@media (max-width: 480px) {



    #pager a {

        font-size: 12px;

    }



    .navbar-toggle {

        background: none !important;

    }



    h1.xlarge {

        font-size: 24px;

        font-weight: 800;

        line-height: normal;

        padding-bottom: 0;

    }



    h1.x2large {

        font-size: 38px;

    }



    .title-big {

        font-size: 38px;

    }



    .sliderTextFull h3 {

        font-size: 16px;

        line-height: normal;

        margin-bottom: 2px;

        margin-top: 0;

        padding-bottom: 0;

    }



    .sliderText h1 {

        font-size: 24px;

        line-height: 26px;

        padding-bottom: 0;

    }



    .sliderInfo .btn-lg {

        font-size: 12px;

        padding: 4px 8px;

    }



    .sliderText .inner {

        padding: 2px;

    }



    .sliderText h3.price {

        font-size: 16px;

        padding-bottom: 2px;

    }



    .supportLi h4 {

        font-size: 14px;

    }



    .cartTableHeader {

        font-size: 12px;

        font-weight: bold;

        text-transform: uppercase;

    }



    .CartProduct .delete i {

        font-size: 16px !important;

    }



    .CartProductThumb a img {

        max-width: 60px !important;

    }



    .CartDescription h4 a {

        font-size: 14px !important;

        line-height: normal;

    }



    .cart-actions button, .cart-actions .wishlist {

        font-size: 14px !important;

        padding: 10px !important;

    }



    .navbar-brand.windowsphone {

        display: block;

        float: left;

    }



    .navbar-brand.windowsphone img {

        max-width: 65px;

    }



    .featuredPostContainer.globalPadding {

        padding: 10px 0 !important;

    }



    .breadcrumb > li {

        font-size: 12px;

    }



    .item.list-view h4 {

        font-size: 20px;

        line-height: 18px;

    }



    .item.list-view .description {

        clear: both;

        display: block;

        float: none;

        padding-top: 5px;

    }



    .item.list-view .image {

        display: block;

        float: none;

        max-height: 500px;

        max-width: 100%;

        overflow: hidden;

        padding-right: 0;

        text-align: center;

    }



    .item.list-view h4, .item.list-view .description, .item.list-view h4, .list-description, .item.list-view .price, .item.list-view .action-control, .item.list-view .description p {

        text-align: center;

    }



    .item.list-view h4 {

        min-height: 24px;

    }



    .col-xs-mini-6 {

        width: 50%;

    }



    .col-xs-min-12, .col-xxs-12 {

        width: 100%;

    }



    .text-center-xs {

        text-align: center;

    }



    .text-center-xs .caps {

        font-size: 14px;

        text-align: center;

    }



    .home-intro h2 {

        font-size: 18px;

        font-weight: 200;

        letter-spacing: 1px;

    }



    .no-float-xs {

        float: none !important;

    }

}



@media (max-width: 400px) {

    #pager a {

        font-size: 10px;

    }



    .container, .container-fluid {

        min-width: 310px;

    }



    .cartFooter .box-footer div {

        width: 100%;

        text-align: center;

        padding-bottom: 5px;

    }



    .navbar-brand {

        padding: 17px 0 0 10px;

    }



    .navbar-brand img {

        max-width: 150px;

    }



    .navbar-header.isios .navbar-brand img {

        max-width: 60px !important;

    }



    .navbar-header .navbar-toggle {

        margin-right: 10px;

        padding: 9px 2px;

    }



    .xsResponse .item, .xs3Response .item {

        width: 100% !important;

    }



    .cartFooter {

        padding: 5px 0;

    }



    .checkoutReview th {

        font-size: 13px;

    }



    .col-xs-mini-6 {

        width: 100%;

    }

}



@media (max-width: 320px) {

    .container {

        width: 100% !important;

        max-width: 320px !important;

    }



    .banner {

        width: 100% !important;

        max-width: 320px !important;

        overflow: hidden !important;

    }

}



/* IMAGE HOVER */



.itemauto {

    height: auto;

    max-height: none;

}



.imageHover {

    display: block;

    max-height: 404px;

    overflow: hidden;

    position: relative;

    text-align: center;

    transition: all 0.5s ease 0s;

    -webkit-transition: all 0.5s ease 0s;

}



.imageHover a {

    display: block;

    overflow: hidden;

}



.img-responsive.primaryImage {

    position: relative;

    z-index: 1;

    transition: all 200ms ease-in-out 0s;

    -webkit-transition: all 200ms ease-in-out 0s;

    transform-style: preserve-3d;

    opacity: 1;

}



.secondaryImage {

    left: 0;

    position: absolute;

    transition: all .10s linear;

    -webkit-transition: all .10s linear;

    top: 0;

    opacity: 0;

}



.imageHoverFlip .primaryImage {

    transform-style: preserve-3d;

    transform: rotateY(0);

    transition: all .10s linear;

    -webkit-transition: all .10s linear;

}



.imageHoverFlip .secondaryImage {

    transform-style: preserve-3d;

    transform: rotateY(180deg);

    transition: all .10s linear;

    -webkit-transition: all .10s linear;

}



.product:hover .secondaryImage {

    opacity: 1;

}



.product:hover .img-responsive.primaryImage {

    opacity: 0;



}



.product:hover .imageHoverFlip .img-responsive.primaryImage {

    transform: rotateY(180deg);

}



.product:hover .imageHoverFlip .img-responsive.secondaryImage {

    opacity: 1;

    transform: rotateY(0deg);



}



.item.list-view .imageHover {

    background: #fff;

    display: block;

    float: left;

    max-height: 260px;

    max-width: 200px;

    overflow: hidden;

    padding-right: 20px;

    position: relative;

    text-align: center;

    transition: all 0.5s ease 0s;

    -webkit-transition: all 0.5s ease 0s;

}



/* carousel-inner */



.carousel-inner > .item {

    height: auto;

    margin-bottom: 0;

}



.carousel-inner > .item > img, .carousel-inner > .item > a > img {

    margin: 0 auto;

    display: block;

}



.imageHover .glyphicon-chevron-right::before {

    content: "\f105";

    font-family: 'FontAwesome' !important;

    text-shadow: none;

    border: 1px solid #999;

    color: #999 !important;

    display: block;

    height: 31px;

    width: 31px;

    padding: 0;

}



.imageHover .glyphicon-chevron-left::before {

    content: "\f104";

    font-family: 'FontAwesome' !important;

    text-shadow: none;

    border: 1px solid #999;

    color: #999 !important;

    display: block;

    height: 31px;

    width: 31px;

    padding: 0;

}



.imageHover .carousel-control.left {

    background-image: none !important;

    z-index: 5;

}



.imageHover .carousel-control.right {

    background-image: none !important;

    z-index: 5;

}



.hoverNav .carousel-control {

    transform: scale(0.1);

    visibility: hidden;

    transition: all 0.3s ease 0.1s;

}



.product:hover .hoverNav .carousel-control {

    transform: scale(1);

    visibility: visible;

}



.imageHover div.quickview {

    margin-top: 60%;

}



.carousel-fade .carousel-inner .item {

    opacity: 0;

    transition-property: opacity;

}



.carousel-fade .carousel-inner .active {

    opacity: 1;

}



.carousel-fade .carousel-inner .active.left,

.carousel-fade .carousel-inner .active.right {

    left: 0;

    opacity: 0;

    z-index: 1;

}



.carousel-fade .carousel-inner .next.left,

.carousel-fade .carousel-inner .prev.right {

    opacity: 1;

}



.carousel-fade .carousel-control {

    z-index: 2;

}



@media (max-width: 767px) {

    .imageHover .glyphicon-chevron-right::before {

        height: 25px;

        width: 25px;

    }



    .imageHover .glyphicon-chevron-left::before {

        height: 25px;

        width: 25px;

    }

}



/* ORDER STATUS */



.order-box {

    background: #fff;

    border: solid 1px #ccc;

    margin-bottom: 30px;

}



.order-box-header {

    background: #ebeded;

    font-weight: bold;

    padding: 10px;

    text-transform: uppercase;

}



.order-box-content {

    padding: 15px;

}



.order-box-content table td {

    padding: 5px;

}



.statusTop {

    margin-bottom: 20px;

}



.statusTop p {

    font-size: 16px;

    margin-bottom: 5px;

}



.order-details-cart {

    width: 100%;

}



.order-details-cart tr {

    border-bottom: solid 1px #ddd;

}



.order-details-cart img {

    width: 80px;

}



.cartTotalTr {

    border: none !important;

}



.cartTotalTr td {

    text-align: right;

}



/* Thanks Page */

.col-center {

    float: none;

    margin-left: auto;

    margin-right: auto;



}



.thanxContent {

    padding: 70px 0 30px;

}



.thanxContent a {

    color: #A2C15A;

}



.thanxContent h1 {

    margin-bottom: 0;

    font-size: 40px;

}



.thanxContent h4 {

    margin-bottom: 0;

    font-size: 16px;

    font-weight: 300;

}



.cartTableBorder {

    border: solid 1px #EBEDEF;

}



/* review modal */



.rating-here .fa, .rating .fa {

    color: #E7711B;

}



.reviewtitle {

}



/* NAV TREE */



.nav-category {

    list-style: outside none none;

    margin: 0;

    padding: 0;

}



.nav-category li a {

    border-top: 1px solid #ddd;

    clear: both;

    display: block;

    font-weight: 600;

    position: relative;

    padding: 10px 20px;

}



.nav-category li a:hover {

    background: #f5f5f5;

}



.nav-category li a.child-has-open:hover {

    background: #fff;

}



.nav-category a span {

    padding-right: 5px;

    display: inline-block;

}



.nav-category li ul {

    margin: 0;

    padding: 0;

    background: #f5f5f5;



}



.nav-category ul a {

    padding-left: 30px;

    font-size: 13px;

}



.nav-category ul ul a {

    padding-left: 45px;

}



.nav-category ul ul ul a {

    padding-left: 60px;

}



.nav-category ul ul ul ul a {

    padding-left: 75px;

}



.nav-category li a.child-has-open {

    font-weight: bold;

}



.child-has-open::after {

    content: "\f107";

    font-family: FontAwesome;

    position: absolute;

    right: 10px;

}



.child-has-close::after {

    content: "\f104";

    font-family: FontAwesome;

    position: absolute;

    right: 10px;

}



/* SELECT 2 PLUGIN CUSTOMIZE */



.select2-container .select2-selection--single {

    height: 37px;

}



.select2-container--default .select2-selection--single {

    background-color: #fff;

    border: 1px solid #dddddd;

    border-radius: 3px 3px;

    border-style: solid;

    border-width: 1px;

}



.select2-container--default .select2-selection--single .select2-selection__rendered {

    color: #888;

    line-height: 36px;

}



.select2-container--default .select2-selection--single .select2-selection__arrow {

    height: 100%;

}



.productFilter .select2-container, .form-group .select2-container {

    width: 100% !important;

}



/* iCheck Customize */



.radio-inline, .checkbox-inline, .radio label, .checkbox label {

    padding-left: 0;

}



.iCheck-margin {

    margin-right: 5px;

}



/* MENU OVERLAPPED */



@media screen and (min-width: 768px) {

    .ProductDetailsList.megamenu-content ul {

        padding-left: 5px;

        padding-right: 5px;

    }



    .ProductDetailsList.megamenu-content ul li a {

        font-size: 11px;

        overflow: hidden;

        text-overflow: ellipsis;

        white-space: nowrap;

    }

}



/* ONEPAGE PAYMENT */



.payment-method {

    display: block;

    float: left;

    margin: 10px 0;

    width: 100%;

}



/* OWL PAGINATION ARROW */



.owl-theme .owl-controls .owl-has-nav {

    display: inline-block;

    font-size: 22px;

    font-weight: bold;

    height: 22px;

    position: relative;

    width: 12px;

    cursor: pointer;

    transition: 0.3s ease;

    opacity: 1;

}



.owl-has-nav:hover {

    opacity: .5;

}



.owl-has-nav i {

    position: absolute;

    top: 0;

}



.owl-has-nav.owl-next i {

    right: 0;

}



.owl-has-nav.owl-prev i {

    left: 0;

}



/* PAGE v-7.1 MENU  */



.container.static, .static {

    position: static;

}



.container.static .megamenu-content.container {

    padding-left: 0;

    padding-right: 0;



}



/* PAGE About 3   */



.about-3 {

    height: auto;

    max-height: none;

    background-position: 50% center;

    background-size: cover;



}



.about-3 .parallax-content {

    padding: 60px 0;



}



.about-3 .w100 {

    background: rgba(0, 0, 0, 0.3);

}



.about-3 .intro-heading {

    font-size: 60px;

    font-weight: 600;

    line-height: 1;

    padding: 150px 0;

}



.about-content {

    max-width: 800px;

    margin: 0 auto;

    clear: both;

}



.lead-2 {

    font-size: 20px;

    font-style: italic;

    font-weight: normal;

    line-height: 1.4;

}



.about-content .lead-2 {

    margin: 50px 0;

}



.abt-img {

    width: 350px;

    margin: 0 auto;

}



.about-content-text {

    max-width: 380px;

    margin: 0 auto;

}



.about-content-text h3 {

    color: #303030;

    font-size: 24px;

    font-weight: 700;

    line-height: normal;

    margin-bottom: 20px;

    margin-top: 0;

    padding-top: 0;

}



.about-content-text > h3::after {

    border-bottom: 2px solid #888888;

    content: "";

    display: block;

    height: 2px;

    margin-top: 30px;

    width: 50px;

}



.about-content-text p {

    color: #888888;

    font-size: 16px;

    line-height: 24px;

}



.preson-row {

    text-align: center;

}



.preson-row .preson {

    display: inline-block;

    width: 230px;

    margin-right: 15px;

    margin-bottom: 15px;

    transition: all 0.3s;

    -webkit-transition: all 0.3s;

}



.preson-row .preson:last-child {

    margin-right: 0;

}



.preson-row .preson img {

    max-width: 80%;

    margin: 0 auto;

    transition: all 0.25s ease;

    -webkit-transition: all 0.25s ease;

    opacity: 1;

}



.preson-row .preson:hover img {

    opacity: .8;

}



.preson-details {

    display: block;

    margin-top: 20px;

    margin-bottom: 15px;

}



.preson-details p {

    font-size: 16px;

    color: #303030;

    font-weight: 700;

}



.preson-details p span {

    color: #888888;

    font-size: 14px;

}



.about-title {

    color: #888888;

    font-size: 24px;

    font-weight: 700;

    margin: 30px 0;

    text-transform: uppercase;

    position: relative;

    display: block;

}



.about-title::after {

    border-bottom: 2px solid #888888;

    content: "";

    display: block;

    height: 2px;

    margin: 15px auto 0;

    width: 50px;

}



.section-people {

    margin-top: 60px;

}



.section-about-bottom {

    padding: 60px 0 200px;

}



@media screen and (max-width: 1200px) {



    .preson-row .preson {

        margin-right: 15px;

        width: 200px;

    }



}



@media screen and (min-width: 1400px) {

    .section-about-bottom {

        background-position: top center !important;

    }



}



@media screen and (max-width: 991px) {



    .preson-row .preson {

        margin-right: 15px;

        width: 160px;

    }



}



@media screen and (max-width: 767px) {



    .section-about-bottom {

        padding: 30px 0 80px;

    }



    .about-content-text h3 {

        margin-top: 15px;

        font-size: 18px;

    }



    .about-3 .intro-heading {

        font-size: 24px;

        font-weight: 600;

        line-height: 1;

        padding: 20px 0;

    }



    .about-content-text p {

        font-size: 14px;

    }



    .lead-2 {

        font-size: 16px;

    }



    .about-content .lead-2 {

        margin: 0 0 15px;

    }



}



.white-bg {

    background: #fff;

}



/* style icon */

.inner-addon .glyphicon {

  position: absolute;

  padding: 10px;

  pointer-events: none;

}



/* align icon */

.left-addon .glyphicon  { left:  0px;}

.right-addon .glyphicon { right: 0px;}



/* add padding  */

.left-addon input  { padding-left:  30px; }

.right-addon input { padding-right: 30px; }




/********** Search box **********/

.inner-addon { 

    position: relative; 

}



.reg-search input[type=text] {

    height: 72px;

    font-size: 3.5em;

    /*font-weight: bold;*/

    text-align: center;

    text-transform:uppercase

}



.reg-search input[type=text]:focus {

  border: 1px solid #999;
   
}



.reg-search img {

    position: relative;

    top: -11px;

    left: -10px;

}


.error-box
{
    color:#FFF;
    padding:10px;
    display:none;
    height:100px;
    min-height:100px;
    text-align:center;
    font-size:16px;
}

.error-box a
{
    color:#FFF;
    text-decoration: underline;
}
/* Search button */

.car-search-box-holder
{
    position: absolute;
    width:100%;
    left:0px;   
    top: 280px;
}


.car-search-box {
    
    margin: 0 auto;

    width:385px;
    max-width:90%;
    z-index: 100;

    /*background: rgba(255, 255, 255, 0.9);*/

    /*border-left: 15px solid rgba(030, 030, 030, 0.9);*/

    /*padding: 30px 15px 0 15px;*/

    /*box-shadow: 10px 10px 5px rgba(030, 030, 030, 0.8);*/

}

.searchButton
{
    font-size:22px !important;
    width:100%;
    color:#FFF;
    background-color: #2f68a3;
    padding-top:15px;
    padding-bottom:15px;
    border-radius:0px; 
    border-style:none;  
}

.car-search-box .btn {

    /*border: 1px solid #FFF;*/

    border-radius:0px;

    color: #555;

    display: inline-block;

    font-weight: 400;

    font-size: 22px;

    text-align: left;

   /* height: 40px;*/

    /*letter-spacing: 0.5px;*/

    padding-left: 20px;

    text-transform: uppercase;

    width: 100%;

    margin-bottom: 10px;

    background: #fff none repeat scroll 0 0;

}



.car-search-box .cart-actions {

    margin-bottom: 10px;

    border-bottom: none;

}



.car-search-box .btn .glyphicon {

    color: green;

    visibility: hidden;

}



.car-search-box .check-btn {

    margin-top: 10px;

}


/* Second tab */

.car-search-box .list {

    height: 259px;

    overflow: scroll;

    padding:0;

    margin-bottom:30px;

}



.car-search-box .list button:focus {

    outline: none;

}



#search-tabs li {

    background-color: #cdcdcd;

}



.car-search-box h2 {

    text-align: center;

}


.select-car-button
{
    color:white;
    font-size:22px;
    width:80%; 
    border:2px white solid;
    padding-top:20px;
    padding-bottom:20px;
    text-align:center;
    margin: 0 auto;
    cursor:pointer;
}



.search-car-list
{
    text-align:center;
    display:none;
}

.search-car-select
{
    color:white;
    font-size:22px;
    width:80%; 
    border:2px white solid;
    padding:10px;
    text-align:center;
    margin: 0 auto;
    cursor:pointer;
    background-color: transparent;
}
.search-car-select option
{
    color:black;
}

@media screen and (max-width: 390px) {

    

    .car-search-box h2 {

        font-size: 140%

    }
}


@media screen and (max-width: 768px) {    

    .car-search-box {
        


    }

     .title-box
    {
        position: absolute;

        top: 60px;

        left:0px;

        width:100%;

        text-align:center;

        padding: 30px 15px 0 15px;
    }
    .title-box h1
    {
        font-size:28px;

        font-style: italic;
        font-weight: bold;

        line-height: normal;    

        color:#FFF;

        text-shadow: 4px 3px 5px #000000;    
    }

    .title-box h2
    {
        font-size:18px;

        font-style: italic;
        font-weight: bold;

        line-height: normal;    

        color:#FFF;

        text-shadow: 4px 3px 5px #000000;    
    }

    .title-box a
    {
        font-size:22px;
        color: #FFF;
    }
}




@media screen and (min-width: 768px) {

    .car-search-box {



    }

    .car-search-box-holder
    {
        position: absolute;
        width:100%;
        left:0px;   
        top: 360px;
    }

    .title-box
    {
        position: absolute;

        top: 180px;

        left:0px;

        width:100%;

        text-align:center;

        padding: 30px 15px 0 15px;
    }
    .title-box h1
    {
        font-size:42px;

        font-style: italic;
        font-weight: bold;

        line-height: normal;    

        color:#FFF;

        text-shadow: 4px 3px 5px #000000;    
    }

    .title-box h2
    {
        font-size:28px;

        font-style: italic;
        font-weight: bold;

        line-height: normal;    

        color:#FFF;

        text-shadow: 4px 3px 5px #000000;    
    }   
 
    .title-box a
    {
        font-size:22px;
        color: #FFF;
    }
}

@media screen and (max-width: 950px) {

    .title-box h1
    {
        font-size:36px;
    }

    .title-box h2
    {
        font-size:20px;
    }
    .title-box a
    {
        font-size:22px;
        color: #FFF;
    }

}



@media screen and (min-width: 1500px) {


}



@media screen and (min-width: 1700px) {



}



@media screen and (min-width: 1900px) {


}



@media screen and (min-width: 2100px) {

 

}



.product-label {

    border: 1px solid #ddd;

    padding: 10px;

    margin-bottom: 0;

    background: #fff;

}



.padding-left-ajust {

    padding-left: 5px !important;

}



.padding-right-ajust {

    padding-right: 5px !important;

}



.select-style {

    /*border: 1px solid #ccc;*/

    width: 100%;

    border-radius: 0px;

    overflow: hidden;

    margin-bottom: 10px;

    padding: 0;

    /*background: #fafafa url("img/icon-select.png") no-repeat 90% 50%;*/

}



.select-style select {

    width: 100%;

    height: 45px !important;

    border-radius:0px;

    font-size:22px;

    border: none;

    box-shadow: none;

    background: #fff;

    background-image: none;

    /*-webkit-appearance: none;*/

}



.select-style select:focus {

    outline: none;

}



.car-search-box .nav-tabs > li.active > a, 

.car-search-box .nav-tabs > li.active > a:hover,

.car-search-box .nav-tabs > li.active > a:focus, 

.car-search-box .nav-tabs > li.active > a {

    /* background: rgba(238, 139, 41, 0.9) none repeat scroll 0 0; */
    background: rgba(41, 164, 238, 0.9) none repeat scroll 0 0;

    text-shadow: 1px 1px #aaa

}



/** Slider */

.slider-item {

    /*width: 90%;  or whatever is required */

    overflow: hidden; /* hide the cropped portion */

    /*border: 3px solid black;*/

}



.slider-item img {

    position: relative; /* allows repositioning */

    left: 50%;

    transform: translate(-50%,0);

    min-height: 501px;

    max-width: none;

}



@media only screen and (min-width: 1400px) {

    .slider-item img {

        width: 100%;

        height: auto;

    }

}



.productFilter .dropdown-menu {

    width: 100%;

}



/* Navigation */

.megamenu .dropdown.megamenu-40width .dropdown-menu {

    width: 20%;

}

.dropdown-style ul  {

    margin:0;

    padding:0;

}



.dropdown-style ul li {

    font-size: 1.2em;

    padding: 5px;

    display: block;

}



.dropdown-menu > .active, .dropdown-menu > .active:hover, .dropdown-menu > .active > a, .dropdown-menu > .active > a:hover{

    background-color: #7c7c7c;

    color: #fff;

}





.dropdown-style ul li:hover, .dropdown-style ul li a:hover  {

    background-color: #7c7c7c;

    color: #fff;

}



@media screen and (min-width: 767px) {

    .dropdown-style ul li {

        font-size: 1.1em;

        padding: 7px;

        display: block;

        border-bottom: 1px solid #ddd;

    }



    .dropdown-style ul.active {

        background-color: none;

    }



    .dropdown-style ul li:last-child {

        border: none;

    }





    .dropdown-menu > .active, .dropdown-menu > .active:hover, .dropdown-menu > .active a, .dropdown-menu > .active a:hover {

        background-color: #7c7c7c;

        color: #fff;

    }

    

    .dropdown-style ul li:hover, .dropdown-style ul li a:hover  {

        background-color: #ddd;

        color: #333;

    }

}





/* Parallax vid footern */

.parallax-image-3 {

    background: rgba(0, 0, 0, 0) url("../../images/parallax/slider_footer2.jpg") no-repeat fixed 0 0;

}



/* Parallax slider för sommardäck*/

.parallax-image-aboutus .parallax-content {

}

@media screen and (min-width: 991px) {

    .parallax-image-rims, .parallax-image-summertires, .parallax-image-friktiontires, .parallax-image-studdedtires  {

        min-height: 325px;

        margin-bottom: -50px;

    }

}



@media screen and (min-width: 1000px) {

    .parallax-image-rims, .parallax-image-summertires, .parallax-image-friktiontires, .parallax-image-studdedtires  {

        min-height: 425px;

        margin-bottom: -50px;

    }

}



@media screen and (min-width: 1100px) {

    .parallax-image-rims, .parallax-image-summertires, .parallax-image-friktiontires, .parallax-image-studdedtires  {

        min-height: 200px;

        margin-bottom: -50px;

    }

}

.parallax-image-rims {

    /*background: rgba(0, 0, 0, 0) url("../../images/slider/streetwheels_rimSlider.jpg") no-repeat 0% -80px / cover ;*/

    background: rgba(0, 0, 0, 0) url("../../images/slider/streetwheels_rimSlider.jpg") no-repeat 0% -120px;

    width: 100%;

    height: auto;

}



.parallax-image-summertires {

    /*background: rgba(0, 0, 0, 0) url("../../images/slider/streetwheels_rimSlider.jpg") no-repeat 0% -80px / cover ;*/

    background: rgba(0, 0, 0, 0) url("../../images/slider/streetwheels_slider6.jpg") no-repeat 0% -100px;

    width: 100%;

    height: auto;

}



.parallax-image-friktiontires {

    /*background: rgba(0, 0, 0, 0) url("../../images/slider/streetwheels_rimSlider.jpg") no-repeat 0% -80px / cover ;*/

    background: rgba(0, 0, 0, 0) url("../../images/slider/streetwheels_slider6.jpg") no-repeat 0% -100px;

    width: 100%;

    height: auto;

}



.parallax-image-studdedtires {

    /*background: rgba(0, 0, 0, 0) url("../../images/slider/streetwheels_rimSlider.jpg") no-repeat 0% -80px / cover ;*/

    background: rgba(0, 0, 0, 0) url("../../images/slider/streetwheels_slider6.jpg") no-repeat 0% -100px;

    width: 100%;

    height: auto;

}



/* Logo */

.navbar-brand {

    padding: 0;

    padding-top: 5px;

    padding-right: 17px;

}



/* Brand märken*/

.owl-item {

    padding-right:40px;

}



.full-width-div {

    position: absolute;

    width: 100%;

    left: 0;

    z-index:1;

}

.btn-group > .btn:active, .btn-group > .btn.active {

    background-color: #173555;

}

.selectDimensionForm {

}



.selectDimensionForm .select-style, .selectDimensionForm select.form-control {

    height: 40px;

    background-color: #173555;

    color: #fff;

    text-align: center;

    font-size: 1em;

}



.productFilter .check-btn .icheckbox_square-green{

    background-color: #fff;

}



.categoryProduct {

    padding:15px;

}



#searchContainer {

   margin-top: -20px;

}



#searchByDimensions {

    border:none;

    margin-bottom: -20px;

}

#complete_wheels_bullets {

    font-size: 1.5em;

}

#complete_wheels_bullets li {

    padding: 5px 10px;

}



.miniCartFooter .btn {

    background-color: #173555;

}



.btn-xlarge {

    padding: 18px 28px;

    font-size: 15px; /*change this to your desired size*/

    line-height: normal;

    -webkit-border-radius: 8px;

       -moz-border-radius: 8px;

            border-radius: 8px;

}


.tooltip.in{

    opacity:.8;

    filter:alpha(opacity=80)

}



.tooltip.bottom .tooltip-inner{

    width:210px;

    padding:3px 8px;

    color:#fff;

    text-align:center;

    background-color:#000000;

    -webkit-border-radius:4px;

    -moz-border-radius:4px;

    border-radius:4px

}



.tooltip.right .tooltip-inner{

    width:210px;

    padding:3px 8px;

    color:#fff;

    text-align:center;

    background-color:#000000;

    -webkit-border-radius:4px;

    -moz-border-radius:4px;

    border-radius:4px

}

/*

.tooltip-arrow{

    position:absolute;

    width:0;

    height:0

}*/




.has-error .help-block {

  height: 40px;

  /*margin: ((@line-height-computed / 2) - 1) 0;*/

  font-weight: bold;

  color: #8C372B;



}



.has-error .help-block2 {

  height: 20px;

  /*margin: ((@line-height-computed / 2) - 1) 0;*/

  font-weight: bold;

  color: #8C372B;



}







#logo img {

    margin-top:0px; 

    height: 65px;

    z-index: 100;

    position: absolute;

    left: 20px;

    top: 14px;

    /*height: auto; 

    width: 50%;

    display:block*/

}

#logo img {


}


#productNameSearchForm {

    width:80%;
}




.hide-s
{
    display:initial;
    font-size:18px;
}

.custom-show-xs
{
    display:none !important;
}

.menuIcons
{
    font-size:20px;
    display:initial;

}

.menuIconsLogin
{
    font-size:20px;
}

.menuIconsHolder
{
    margin-top:35px;
}
@media only screen and (max-width: 1430px) {

    #productNameSearchForm {

    
    }



}



@media only screen and (max-width: 1030px) {

    #productNameSearchForm {

    }


    .hide-s
    {
        display:none;
    }
  


    .menuIcons
    {
        font-size:30px;
    }

    .menuIconsHolder
    {
        margin-top:30px;
    }

}

@media only screen and (max-width: 767px) {
   #productNameSearchForm {

    }

    .custom-show-xs
    {
        display:initial !important;
    }  

}


@media only screen and (max-width: 560px) {

    #logo img {

    }

    #productNameSearchForm {

    }

}



@media screen and (min-width: 768px) {

    .dropdown-nav {

        position: absolute !important; left: 50% !important;

    }

}



/*@media (min-width: 1200px) {

    .container{

        width: 1300px;

    }

}*/





/** Webshim for safari html validation **/

div.ws-po-box{

    padding: 20px;

    border: 1px solid #bbb;

    box-shadow: 0px 0px 5px #999;

}





/** Banner styling for fronpages **/



.main-banner {

    background-repeat:no-repeat;

    background-position:top right;

    height: 900px;

    background-size: cover;

    background-attachment: fixed;

}



.banner2{

    /*background-image: url("../../images/slider/testSlider4.jpg");*/

/*    background-repeat:no-repeat;

    background-position:top right;

    height: 780px;

    background-size: cover;

    background-attachment: fixed;*/

    /*top:100px;*/

}



.main-banner-mobile{

    background-image: url("../../images/slider/testSlider4-mini.jpg");

    background-repeat:no-repeat;

    background-position:top right;

    background-size: cover;

    background-attachment: relative;

    height: 680px;


    /*top:100px;*/

}



.banner3{

    background-image: url("../../images/slider/hjulonline_rimBanner2.jpg");

    background-repeat:no-repeat;

    background-position:top right;

    height: 900px;

    background-size: cover;

    background-attachment: fixed;

    /*top:100px;*/

}



.banner4{

    background-image: url("../../images/slider/hjulonline_summerTireBanner.jpg");

    background-repeat:no-repeat;

    background-position:top right;

    height: 900px;

    background-size: cover;

    background-attachment: fixed;

    /*top:100px;*/

}



.banner5{

    background-image: url("../../images/slider/hjulonline_winterTireBanner.jpg");

    background-repeat:no-repeat;

    background-position:top right;

    height: 900px;

    background-size: cover;

    background-attachment: fixed;

    /*top:100px;*/

}

@media screen and (max-width: 767px)  {



    .banner2,

    .banner3,

    .banner4,

    .banner5 {

        height: 680px;
    }
}
/*
@media screen and (min-width: 1200px) {

    .banner2,

    .banner3,

    .banner4,

    .banner5 {

        height: 900px;
    }

}
*/



/** three boxes on front-page **/

.panel-body{

    /*border: 1px dotted #cdcdcd; */

    /*background-color: #E3E3E3;*/

}   

.panel-heading{

    /*border-top: 4px solid #333;*/

    /*text-align: center;*/

    background-color: #173555;

    color: #FFF;

}



.three-boxes {

    margin-top: -25px

}




@media screen and (min-width: 768px) {

    .three-boxes {

        margin-top: -150px

    }
}

@media screen and (min-width: 1199px) {

    .three-boxes {

        margin-top: -200px

    }
}

/** header **/
.custom-navbar {

    z-index: 1000;

    position: fixed;

    top: 90px;

    background-color:#173555;
    /*background-color: #323231;*/

    width: 100%;

}


@media screen and (max-width: 767px)  {

    .custom-navbar {

        z-index: 1000;

        position: fixed;

        top: 0px;

        background-color:#173555;

        width: 100%;

    }


}



.searchDataBorder {

    padding-left:30px;

}



@media screen and (min-width: 768px)  {

    .searchDataBorder {

        border-left: 1px dashed #ccc;

        padding-left:40px

    }

}

.navbar-header {

    border-bottom: 0px solid #000;

}

.indexPageContainer {

    padding-right: 0; 

    /*padding-bottom: 25px; */

    padding-top: 5px;

    box-shadow: 0 4px 2px -2px #555;

}

.pageContainer {

    padding-right: 0; 

    padding-bottom: 25px; 

    padding-top: 5px;

    box-shadow: 0 4px 2px -2px #555;

}

.rollingResistance {

    background: #ffffff url("../../images/product/rollingResistance.png") no-repeat left;

    display: block;

    padding: 12px 4px 4px 12px;

}



.rollingResistance {

    background: #ffffff url("../../images/product/rollingResistance.png") no-repeat left;

    display: block;

    padding: 12px 4px 4px 12px;

}

.wetGrip {

    background: #ffffff url("../../images/product/wetGrip.png") no-repeat left;

    display: block;

    padding: 12px 4px 4px 12px;

}

.snowGrip {

    background: #ffffff url("../../images/product/snowGrip.png") no-repeat left;

    display: block;

    padding: 12px 4px 4px 12px;

}

.iceGrip {

    background: #ffffff url("../../images/product/iceGrip.png") no-repeat left;

    display: block;

    padding: 12px 4px 4px 12px;

}

.noiseEmissionDecibel {

    background: #ffffff url("../../images/product/noiseEmissionDecibel.png") no-repeat left;

    display: block;

    padding: 12px 11px 4px 19px;

}

.noiseEmissionDecibelA {

    background: #ffffff url("../../images/product/noiseEmissionDecibel_A.png") no-repeat left;

    display: block;

    padding: 12px 11px 4px 19px;

}

.noiseEmissionDecibelB {

    background: #ffffff url("../../images/product/noiseEmissionDecibel_B.png") no-repeat left;

    display: block;

    padding: 12px 11px 4px 19px;

}

.noiseEmissionDecibelC {

    background: #ffffff url("../../images/product/noiseEmissionDecibel_C.png") no-repeat left;

    display: block;

    padding: 12px 11px 4px 19px;

}


.toast-success, .toast-error, .toast-warning {

    top: 30px;
}

.strike {

    position: relative;
}

.strike::before {

    content: '';

    border-bottom: 2px solid #C72B26;

    width: 75%;

    position: absolute;

    right: 25%;

    top: 50%;

    border-bottom: 2px solid #C72B26;

    -webkit-transform: skewY(-10deg);

    transform: skewY(-10deg);

}


@media screen and (min-width: 468px)  {

    .strike::before {

        width: 65%;

    }

}


@media screen and (min-width: 968px)  {

    .strike::before {

        width: 55%;

    }

}

@media screen and (min-width: 1168px)  {

    .strike::before {

        width: 50%;

    }

}

#suggestionBox {

    background-color: #fff; 

    position: fixed; 

    z-index: 1300;

}

#suggestionBox li {

    padding: 5px;

}

#suggestionBox li:hover {

    color: #771E21;

    background-color: #ddd;

    border: 1px solid #bbb;

}

.icon-flipped {

    transform: scaleX(-1);

    -moz-transform: scaleX(-1);

    -webkit-transform: scaleX(-1);

    -ms-transform: scaleX(-1);

}

    #loadContainer
    {
        display: none;
        margin-top:-6px;
    }

    #DDDirektNav
    {

        padding-top:5px;
        padding-left:180px;

    }
   
    @media (max-width: 768px)
    {
        #DDDirektNav
        {
            padding-left:0px;
            padding-top:20px;
            font-size:16px;

        }

        #DDBrands
        {
            font-size:16px;
        }

    }

.wideCardTable
{
    width:100%;
    position:absolute;
    bottom:0px;
}

.widteCardLeftTd
{
    width:40% !important;
}

.wideCardTdInputText
{
    border-radius:0px !important;
    font-size:20px !important;
    margin-bottom:0px !important;
    width:100% !important;
    text-align:center !important;
    height:40px !important;
}

.wideCardTdInputButton
{
    border-style:none !important;
    border-radius:0px !important;
    background-color:#2f68a3;
    color:#FFF;
    width:100% !important;
    height:40px !important;
}

.wideCardTdInputButton:hover
{
       background-color:#1b426b; 
}

.wideCardTdInputButton i
{
    font-size:20px;
}
 
#loadContainer
{
    display:none;
    margin-bottom:-15px;
    margin-top:-6px;
}

@media (max-width: 768px)
{
    .customSelectField
    {
        font-size:16px !important;
        background: url(data:image/svg+xml;base64,PHN2ZyBpZD0iTGF5ZXJfMSIgZGF0YS1uYW1lPSJMYXllciAxIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA0Ljk1IDEwIj48ZGVmcz48c3R5bGU+LmNscy0xe2ZpbGw6I2ZmZjt9LmNscy0ye2ZpbGw6IzQ0NDt9PC9zdHlsZT48L2RlZnM+PHRpdGxlPmFycm93czwvdGl0bGU+PHJlY3QgY2xhc3M9ImNscy0xIiB3aWR0aD0iNC45NSIgaGVpZ2h0PSIxMCIvPjxwb2x5Z29uIGNsYXNzPSJjbHMtMiIgcG9pbnRzPSIxLjQxIDQuNjcgMi40OCAzLjE4IDMuNTQgNC42NyAxLjQxIDQuNjciLz48cG9seWdvbiBjbGFzcz0iY2xzLTIiIHBvaW50cz0iMy41NCA1LjMzIDIuNDggNi44MiAxLjQxIDUuMzMgMy41NCA1LjMzIi8+PC9zdmc+) no-repeat 100% 50% !important;
        -moz-appearance: none !important; 
        -webkit-appearance: none !important; 
        appearance: none !important;
    }
    .customSelectField option
    {
        background-color:#173555;
    }
}

.loadColor
{
        background-color:#c1b8ae !important;   
}

.iconColor
{
    color:#ff0000;
}

.menuSearch
{
    background-color:#ff0000;
}

.menuSearch:hover
{
    background-color:#be0000;
}


    .tireTile
    {
        background-color:#2f68a3;
        height: 100%;
        height: 75px;
        padding-top: 12px;
        color: #FFF;
        margin-bottom: 20px;

    }
    .tireTile:hover
    {
        background-color:#1b426b;
    }

    .tileHeader
    {
        font-size: 20px;
        padding-bottom: 3px;
    }

    .tileBrand
    {
        font-size: 16px;
        padding-bottom: 3px;
        text-transform: uppercase;
    }

    .tireTileActive
    {
        background-color:#1b426b !IMPORTANT;
        cursor: default;
    }
    .tireTileNone
    {
        background-color:#d1e2f3 !IMPORTANT;
        cursor: default;
    }

    .headerBrand
    {
        text-transform: uppercase;
    }

    .modelLabel {
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      max-width: 150px;
      padding:10px;
      font-size:16px;
      border-color:#DDDDDD;
      border-width:0px 0px 1px 0px;
      border-style:solid;
    }

    .fromtop
    {
        margin-top: -30px;
    }
        
    @media (max-width: 768px)
    {
        .fromtop
        {
            width: 100vw;
            position: relative;
            margin-left: -50vw;
            margin-top: -44px;
            left: 50%;
        }
    }

    .mainText img
    {
      
        max-width: 100%;
        width: auto;
        height: auto;        
    }